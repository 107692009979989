import {
  formatStatusCode,
  formatStatusReasonCode,
  formatMoney,
  convertStateAbbreviationToWord,
} from "../../../../utils/format";
import { formatDate } from "../../../../utils/dates";
import {
  deathBenefitOptionText,
  getOccupationCodeData,
  getProductName,
} from "./detailHelper";
import {
  compareArrayOfStrings,
  compareStrings,
  stringIsEmptyOrNullish,
  extractDigitsfromNumber,
} from "../../../../utils/string";
import { isDefined } from "../../../../utils/evaluate";
import { getTableRatingFromCustomers } from "../../types/life/hooks";
import {
  getFIORiderData,
  getUser,
  userTypes,
} from "../../../../utils/findData";
import { DATA_UNAVAILABLE } from "../../../../constants/ui";
import {
  isDisabilityPolicy,
  isNTLPolicy,
  isPallmPolicy,
  isTermCoverPath,
} from "../../../../utils/policies";
import { useVerifyCashValueQuoteLink } from "../../pages/cashValues/restrictions";
import { convertToNumber } from "../../../../utils/number";

export const formatData = (props) => {
  const { data } = props;

  const forfeitureCodeDisplay =
    data.nonForfeitureOptionCode || "Not Applicable";
  const partialSurrenderAmtDisplay =
    stringIsEmptyOrNullish(data.netSurrenderAmount) ||
    data.netSurrenderAmount === 0
      ? ""
      : formatMoney(data.netSurrenderAmount * 0.75);
  const underwritingClass =
    data.agreementCoverages.find((item) => item.category === "B")
      ?.smokerStatusDescription || "Unavailable";

  let convertiblilityStatus;
  if (
    !compareStrings(data.status, "IF") ||
    compareStrings(data.convertibleIndicator, "No")
  ) {
    convertiblilityStatus = "Not Convertible";
  } else if (!isDefined(data.convertibleIndicator)) {
    convertiblilityStatus = isTermCoverPath(data) ? "-" : "Unavailable";
  } else {
    convertiblilityStatus = "Convertible";
  }

  let convertibilityExpDate;
  if (!isDefined(data.conversionEndDate)) {
    convertibilityExpDate = isTermCoverPath(data) ? "-" : "Unavailable";
  } else {
    convertibilityExpDate = formatDate(data.conversionEndDate);
  }

  let formattedNetDeathBenefitAmount;
  if (!isDefined(data.netDeathBenefitAmount)) {
    formattedNetDeathBenefitAmount = isTermCoverPath(data)
      ? "-"
      : "Unavailable";
  } else {
    formattedNetDeathBenefitAmount = formatMoney(data.netDeathBenefitAmount);
  }

  const insured = getUser({
    people: data.agreementCustomers,
    type: userTypes.INSURED,
  });

  const issueAge = isDefined(insured.issueAge)
    ? insured.issueAge
    : "Unavailable";

  const { occupationalCode, occupationalCodeToolTipText } =
    getOccupationCodeData(props.data);

  const { hasFIORider, nextExerciseDateFIO, faceAmountFIO } = getFIORiderData(
    props.data
  );

  const issueDate =
    data.agreementKeyAdmin === "VNTG1"
      ? formatDate(data.effectiveDate, "", "Unavailable")
      : formatDate(data.issueDate, "", "Unavailable");
  const netSurrenderAmountValue = data?.netSurrenderAmount
    ? convertToNumber(data?.netSurrenderAmount)
    : 0;
  const ltcirReturnPremiumAmountValue = data?.ltcirReturnPremiumAmount
    ? convertToNumber(data?.ltcirReturnPremiumAmount)
    : 0;
  let formattedNetSurrenderAmountForLtcir =
    netSurrenderAmountValue + ltcirReturnPremiumAmountValue;
  if (!data?.netSurrenderAmount && !data?.ltcirReturnPremiumAmount) {
    formattedNetSurrenderAmountForLtcir = undefined;
  }

  return {
    ...data,
    productName: getProductName(data),
    underwritingClass,
    forfeitureCodeDisplay,
    occupationalCode,
    occupationalCodeToolTipText,
    series: translateRateCodeforPolicies(data),
    status: `${formatStatusCode(data.status)}, ${formatStatusReasonCode(
      data.statusReason,
      data.lineOfBusinessCode
    )}`,
    faceAmount: formatMoney(data.faceAmount, "-"),
    netDeathBenefitAmount: formatNetDeathBenefitAmount({
      netDeathBenefitAmount: data.netDeathBenefitAmount,
      agreementKeyAdmin: data.agreementKeyAdmin,
    }),
    formattedNetDeathBenefitAmount,
    monthlyBenefitMaximumAmount: formatMoney(data.monthlyBenefitMaximumAmount),
    issueDate,
    effectiveDate: formatDate(data.effectiveDate, "", "Unavailable"),
    issueAge,
    anniversaryDate: formatDate(data.anniversaryDate, "", "Unavailable"),
    requestedMaturityDate: formatDate(data.requestedMaturityDate),
    totalCashValueAmount: data.totalCashValueAmount,
    netSurrenderAmount: data.netSurrenderAmount,
    formattedTotalCashValueAmount: formatMoney(data.totalCashValueAmount),
    formattedNetSurrenderAmount: formatMoney(data.netSurrenderAmount),
    formattedNetSurrenderAmountForLtcir: formatMoney(
      formattedNetSurrenderAmountForLtcir
    ),
    jurisdictionState: convertStateAbbreviationToWord(
      data.jurisdictionState,
      DATA_UNAVAILABLE
    ),
    deathBenefitOption: deathBenefitOptionText(
      data.agreementKeyAdmin,
      data.deathBenefitOption,
      DATA_UNAVAILABLE
    ),
    partialSurrenderAmtDisplay,
    totalAccountValue: formatMoney(data.totalAccountValue),
    ltcResidualDeathBenefitAmount: formatMoney(
      data.ltcResidualDeathBenefitAmount
    ),
    ultimateFaceAmount: formatMoney(data.ultimateFaceAmount),
    displayResidualDeathBenefit: showResidualDeathBenefit(data),
    displayUltimateFaceAmount: showUltimateFaceAmount(data),
    convertiblilityStatus,
    convertibilityExpDate,
    hasFIORider,
    nextExerciseDateFIO,
    faceAmountFIO,
  };
};

export const showResidualDeathBenefit = (props) => {
  const {
    agreementKeyAdmin = "",
    lineOfBusinessCode = "",
    majorProductCode = "",
    agreementCoverages = "",
  } = props;
  const coverage = agreementCoverages.filter(
    (agreementCoverage) => agreementCoverage.type === "LTCR"
  )[0];

  if (!isDefined(coverage)) return false;

  const { category = "", status = "" } = coverage;
  const displayResidualDeathBenefit =
    agreementKeyAdmin === "VNTG1" &&
    lineOfBusinessCode === "LIFE" &&
    majorProductCode === "PERM" &&
    category === "R" &&
    status === "ACT";

  return displayResidualDeathBenefit;
};

export const showUltimateFaceAmount = (props) => {
  const {
    agreementKeyAdmin = "",
    lineOfBusinessCode = "",
    majorProductCode = "",
    minorProductCode = "",
  } = props;
  const displayUltimateFaceAmount =
    agreementKeyAdmin === "OPM" &&
    lineOfBusinessCode === "LIFE" &&
    majorProductCode === "PERM" &&
    minorProductCode === "EWL";

  return displayUltimateFaceAmount;
};

export const formatNetDeathBenefitAmount = (props) => {
  const { netDeathBenefitAmount, agreementKeyAdmin } = props;
  switch (agreementKeyAdmin) {
    case "PE1":
    case "LIFCOM":
    case "LVRGVL":
      return null;
    default:
      return netDeathBenefitAmount;
  }
};

export const eligibleCashValueValidation = (props) => {
  const {
    agreementRequest: {
      data: { agreementKeyAdmin, majorProductCode },
    },
  } = props;

  const nonEligibleAdminSystems = ["VNTG1", "OPM", "MPR", "HAP", "PALLM"];
  const IS_NOT_ELIGIBLE =
    compareStrings(majorProductCode, "TERM") &&
    compareArrayOfStrings(nonEligibleAdminSystems, agreementKeyAdmin);

  if (IS_NOT_ELIGIBLE) {
    return false;
  }
  return true;
};

export const displayUnsupportedCashValueMessage = (props) => {
  const {
    agreementRequest: {
      data: { agreementKeyAdmin, productTypeCode },
    },
  } = props;

  const nonEligibleAdminSystems = ["LIFCOM", "LVRGVL", "PE1"];
  const nonEligibleProductTypeCode = ["CM2", "SWL"];
  return (
    compareArrayOfStrings(nonEligibleAdminSystems, agreementKeyAdmin) ||
    (compareStrings("VNTG1", agreementKeyAdmin) &&
      compareArrayOfStrings(nonEligibleProductTypeCode, productTypeCode))
  );
};

export const useDetailProps = (props) => {
  const { agreementRequest } = props;
  const agreementData = agreementRequest.data;
  const {
    agreementCustomers,
    agreementKeyAdmin,
    issueDate,
    effectiveDate,
    lineOfBusinessCode,
  } = agreementRequest.data;
  const permanentTableRatingCode1 = getTableRatingFromCustomers({
    agreementCustomers,
  });
  const issueDateValueResult =
    compareStrings(agreementKeyAdmin, "PE1") &&
    compareStrings(lineOfBusinessCode, "LIFE")
      ? effectiveDate
      : issueDate;

  const issueDateValue = isDefined(issueDateValueResult)
    ? formatDate(issueDateValueResult)
    : "-";

  const isPallmNTLPolicy = isPallmPolicy(agreementData) && isNTLPolicy(props);
  const { isEligibleForCashValueQuoteLink } =
    useVerifyCashValueQuoteLink(agreementRequest);

  return {
    eligibleCashValueValidation,
    displayUnsupportedCashValueMessage,
    data: formatData({ data: agreementRequest.data }),
    permanentTableRatingCode1,
    isPallmNTLPolicy,
    issueDateValue,
    isEligibleForCashValueQuoteLink,
  };
};

export const translateRateCodeforPolicies = (props) => {
  const { agreementKeyAdmin, rateCode } = props;
  const isDisabilityPolicyResult = isDisabilityPolicy(props);
  let formattedSeriesValue = null;
  if (isDisabilityPolicyResult) {
    if (compareStrings(agreementKeyAdmin, "EDS-DI")) {
      if (isDefined(rateCode)) {
        if (rateCode === 1301) {
          formattedSeriesValue = "2014";
        } else if (rateCode === 1) {
          formattedSeriesValue = "2000";
        } else {
          const rateCodeLength = rateCode.toString().length;
          switch (rateCodeLength) {
            case 4: {
              const defaultSeriesValue = formatSeriesYear({
                rateCode,
                startingIndex: 0,
                numOfDigits: 2,
                prefixYearValue: "20",
              });
              formattedSeriesValue =
                defaultSeriesValue > new Date().getFullYear()
                  ? formatSeriesYear({
                      rateCode,
                      startingIndex: 0,
                      numOfDigits: 2,
                      prefixYearValue: "19",
                    })
                  : defaultSeriesValue;
              break;
            }
            case 3: {
              formattedSeriesValue = formatSeriesYear({
                rateCode,
                startingIndex: 0,
                numOfDigits: 1,
                prefixYearValue: "200",
              });
              break;
            }
            default:
              formattedSeriesValue = `-`;
              break;
          }
        }
      } else {
        formattedSeriesValue = "-";
      }
    }
    if (
      compareStrings(agreementKeyAdmin, "MPR") ||
      compareStrings(agreementKeyAdmin, "OPM")
    ) {
      if (rateCode === 0 && compareStrings(agreementKeyAdmin, "MPR")) {
        formattedSeriesValue = `1971`;
      } else {
        formattedSeriesValue = formatSeriesYear({
          rateCode,
          startingIndex: 0,
          numOfDigits: 2,
          prefixYearValue: "19",
        });
      }
    }
  }
  return formattedSeriesValue;
};

export const formatSeriesYear = ({
  rateCode,
  startingIndex,
  numOfDigits,
  prefixYearValue,
}) => {
  let formattedRateCode = null;
  if (isDefined(rateCode)) {
    const rateCodeValue = extractDigitsfromNumber(
      rateCode,
      startingIndex,
      numOfDigits
    );
    formattedRateCode = `${prefixYearValue}${rateCodeValue}`;
  } else {
    formattedRateCode = `-`;
  }
  return formattedRateCode;
};
