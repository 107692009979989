import { useState } from "react";
import { useParams } from "react-router-dom";
import { differenceInCalendarDays } from "date-fns";
import { useUserState } from "../../../../contexts/user";
import { formatDate, isSameDate } from "../../../../utils/dates";
import { fireTealiumEvent } from "../../../../utils/tealium";
import {
  isDisabilityPolicy,
  isPolicyInForce,
} from "../../../../utils/policies";
import { useHideEdsDiAsOfDate } from "../../../../utils/dataMasking";
import { getFIORiderData } from "../../../../utils/findData";
import { convertToTitleCase } from "../../../../utils/format";
import { compareStrings } from "../../../../utils/string";

export const useDisabilityPolicyDetailProps = (props) => {
  const {
    apiRequests: { agreementRequest },
  } = props;

  const { policyId, asOfDate, status, lineOfBusinessCode, agreementKeyAdmin } =
    agreementRequest.data;
  const { agreementKey } = useParams();
  const { nextExerciseDateFIO, productEnterpriseName } = getFIORiderData(
    agreementRequest.data
  );
  const formattedRiderName = convertToTitleCase(productEnterpriseName);
  const fromattednextExerciseDateFIO = formatDate(
    nextExerciseDateFIO,
    "MM/DD/YYYY"
  );

  const showFIOInsurabilityRiderMessageResult = showFIOInsurabilityRiderMessage(
    nextExerciseDateFIO,
    lineOfBusinessCode,
    agreementKeyAdmin,
    status
  );

  const fioInsurabilityRiderMessageText = `This policy includes the ${formattedRiderName}. This Rider provides the opportunity to apply for additional disability income coverage during specified option periods without medical underwriting (provided the Insured is actively at work and not disabled). Your current Option Period is now available and will end on ${fromattednextExerciseDateFIO}.`;

  const formattedAsOfDate = formatDate(asOfDate, "monthCommaDayYear");

  const [isDrawerOpened, setDrawerOpened] = useState(false);
  const [customer, setCustomer] = useState();
  const openDrawer = (customerObject) => () => {
    setCustomer(customerObject);
    setDrawerOpened(true);
  };
  const closeDrawer = () => setDrawerOpened(false);

  const {
    user: { email },
  } = useUserState();

  const printPDP = () => {
    fireTealiumEvent({
      label: "SVNT",
      action: "Print",
      category: "Policy Details",
      custId: email,
    });
    window.print();
  };

  const hideEdsDiAsOfDate = useHideEdsDiAsOfDate({ agreementKeyAdmin });

  return {
    policyId,
    agreementKey,
    policyInForce: isPolicyInForce(status),
    asOfDate: formattedAsOfDate,
    printPDP,
    isDrawerOpened,
    customer,
    openDrawer,
    closeDrawer,
    email,
    lineOfBusinessCode,
    hideEdsDiAsOfDate,
    showFIOInsurabilityRiderMessageResult,
    fioInsurabilityRiderMessageText,
  };
};

export const showFIOInsurabilityRiderMessage = (
  nextExerciseDateFIO,
  lineOfBusinessCode,
  agreementKeyAdmin,
  status
) => {
  const today = new Date();
  const nextExerciseDate = new Date(nextExerciseDateFIO);
  const daysUntilNextExerciseDate = differenceInCalendarDays(
    nextExerciseDate,
    today
  );
  const nextExerciseDateFIOIsSameDate = isSameDate(
    nextExerciseDateFIO,
    today.toString()
  );
  const isEdsDiPolicy =
    isDisabilityPolicy({ lineOfBusinessCode }) &&
    compareStrings(agreementKeyAdmin, "EDS-DI");
  const isPolicyInForceResult = isPolicyInForce(status);
  const isWithin90Days =
    daysUntilNextExerciseDate > 0 && daysUntilNextExerciseDate <= 90;
  return (
    (isWithin90Days || nextExerciseDateFIOIsSameDate) &&
    isPolicyInForceResult &&
    isEdsDiPolicy
  );
};
