import { isObject } from "@okta/okta-auth-js";
import {
  isArray,
  isDefined,
  isNotArray,
  isANumber,
  isEmptyObject,
} from "./evaluate";
import { convertToNumber } from "./number";
import { compareArrayOfStrings, compareStrings, isString } from "./string";
import { getFirstItem } from "./array";
import {
  cutPrecedingZerosFromId,
  formatMoney,
  formatName,
  formatPercentage,
  nameFormattingOptions,
  formatAddress,
} from "./format";
import {
  BILLING_DESCRIPTION_ANNUAL,
  BILLING_DESCRIPTION_MONTHLY,
  BILLING_DESCRIPTION_QUARTERLY,
  BILLING_DESCRIPTION_SEMI_ANNUAL,
} from "../constants/logic";
import { typesOfAgents } from "../data/typesOfAgents";
import { isDisabilityPolicy, isRegisteredOnMM } from "./policies";
import { DATA_UNAVAILABLE } from "../constants/ui";
import { formatDate } from "./dates";

export const userTypes = {
  OWNER: "OWNR",
  INSURED: "INSD",
  PREMIUM_PAYER: "PPAY",
  BENEFICARY: "BENE",
  ASSIGNEE: "ASGN",
  POWER_OF_ATTORNEY: "POA",
  ALTERNATE_ADDRESSEE: "RCP",
};

export const agentTypes = {
  WRITING_AGENT: "WRTG",
  SERVICING_AGENT: "SVC",
};

export const communicationTypes = {
  EMAIL: "EMAIL",
  RES: "RES",
  PREF: "PREF",
  FAX: "FAX",
};

export const getUser = (props) => {
  const defaultReturn = {
    fullName: "",
  };
  const { type, people } = props;
  const person = people?.reduce((foundUser, potentialUser) => {
    if (isDefined(foundUser)) {
      return foundUser;
    }
    const { roleType } = potentialUser;
    const FOUND_USER = compareStrings(roleType, type);
    if (FOUND_USER) {
      return potentialUser;
    }
    return foundUser;
  }, null);

  if (isDefined(person)) {
    return { ...person, fullName: person?.fullName };
  }
  return defaultReturn;
};

export const getUserBySubType = (props) => {
  const defaultReturn = {
    fullName: "",
  };
  const { type, subType, people } = props;
  const person = people?.reduce((foundUser, potentialUser) => {
    if (isDefined(foundUser)) {
      return foundUser;
    }
    const { roleType, roleSubType } = potentialUser;
    const FOUND_USER =
      compareStrings(roleType, type) && compareStrings(roleSubType, subType);
    if (FOUND_USER) {
      return potentialUser;
    }
    return foundUser;
  }, null);

  if (isDefined(person)) {
    return { ...person, fullName: person?.fullName };
  }
  return defaultReturn;
};

export const getBest = (items, defaultReturn = {}) => {
  if (isNotArray(items)) return defaultReturn;
  const defaultItem = getFirstItem(items, defaultReturn);

  return items.reduce((bestItem, currentItem) => {
    const BEST_ITEM_FOUND = arrayHasBest(bestItem?.types);
    const HAS_BEST_ITEM = arrayHasBest(currentItem?.types);
    if (HAS_BEST_ITEM && !BEST_ITEM_FOUND) {
      return currentItem;
    }
    return bestItem;
  }, defaultItem);
};

export const getAttentionLineByRole = (props) => {
  const { customerAgreements = [], roles = [], agreementKey } = props;
  let filteredAgreements = {};
  if (roles.length > 1 && !compareArrayOfStrings(roles, "OWNR")) {
    return "";
  }
  if (isDefined(customerAgreements)) {
    if (roles.length > 1 && compareArrayOfStrings(roles, "OWNR")) {
      filteredAgreements = customerAgreements.filter(
        (agreement) =>
          compareArrayOfStrings(roles, "OWNR") &&
          compareStrings(agreement.agreementKey, agreementKey)
      );
    }
    if (roles.length === 1) {
      filteredAgreements = customerAgreements.filter(
        (agreement) =>
          compareArrayOfStrings(roles, agreement.roleType) &&
          compareStrings(agreement.agreementKey, agreementKey)
      );
    }
  }
  return filteredAgreements[0]?.attention || "";
};

export const arrayHasBest = (list) => {
  const defaultReturn = false;

  if (!isArray(list)) return defaultReturn;
  const filteredList = list.filter((item) => compareStrings(item.code, "BEST"));
  return filteredList.length > 0;
};

export const getFirstWritingAgent = (props) =>
  getFirstMatchingAgent({
    agents: props.agents,
    agentCode: agentTypes.WRITING_AGENT,
  });

export const getFirstServicingAgent = (props) =>
  getFirstMatchingAgent({
    agents: props.agents,
    agentCode: agentTypes.SERVICING_AGENT,
  });

export const getAllWritingAgent = (props) =>
  getAllMatchingAgent({
    agents: props.agents,
    agentCode: agentTypes.WRITING_AGENT,
  });
export const getAllServicingAgent = (props) =>
  getAllMatchingAgent({
    agents: props.agents,
    agentCode: agentTypes.SERVICING_AGENT,
  });

export const getFirstMatchingAgent = (props) => {
  const { agents, agentCode } = props;
  const defaultReturn = {
    fullName: "",
    businessPartnerId: "",
    phoneNumber: "",
    formattedPhoneNumber: "",
    emailAddress: "",
    collaborativePracticeId: "",
    validAgent: false,
  };

  if (!isArray(agents) || !isString(agentCode)) return defaultReturn;

  const matchingAgent = agents.reduce((foundAgent, potentialAgent) => {
    if (isDefined(foundAgent)) {
      return foundAgent;
    }
    const { agreements } = potentialAgent;
    const filteredAgreements = agreements?.filter(
      (roleData) =>
        compareStrings(roleData.subRoleCode, agentCode) &&
        compareStrings(roleData.roleCode, "AGT")
    );
    const FOUND_AGENT = filteredAgreements?.length > 0;
    if (FOUND_AGENT) {
      return potentialAgent;
    }
    return foundAgent;
  }, null);

  if (isDefined(matchingAgent)) {
    return {
      ...matchingAgent,
      businessPartnerId: convertToNumber(matchingAgent.businessPartnerId),
      phoneNumber: matchingAgent.phoneNumber,
      formattedPhoneNumber: matchingAgent.phoneNumber,
      collaborativePracticeId: convertToNumber(
        matchingAgent.collaborativePracticeId
      ),
      emailAddress: matchingAgent.emailAddress,
      validAgent: true,
    };
  }
  return defaultReturn;
};

export const getAllMatchingAgent = (props) => {
  const { agents, agentCode } = props;
  const defaultReturn = [];
  if (!isArray(agents) || !isString(agentCode)) return defaultReturn;

  const filteredAgents = agents.filter((agent) => {
    const { agreements } = agent;
    return isDefined(agreements);
  });

  const agentAgreementsArrays = agents.map((agent) => agent.agreements);
  const subRoleCodes = [];
  agentAgreementsArrays.forEach((item) => subRoleCodes.push(...item));

  return filteredAgents.reduce((foundAgents, potentialAgent) => {
    const { agreements } = potentialAgent;
    const filteredAgreements = agreements?.filter(
      (roleData) =>
        compareStrings(roleData.subRoleCode, agentCode) &&
        compareStrings(roleData.roleCode, "AGT")
    );
    const FOUND_AGENT = filteredAgreements?.length > 0;
    if (FOUND_AGENT) {
      return [
        ...foundAgents,
        {
          ...potentialAgent,
          businessPartnerId: convertToNumber(potentialAgent.businessPartnerId),
          phoneNumber: potentialAgent.phoneNumber,
          formattedPhoneNumber: potentialAgent.phoneNumber,
          collaborativePracticeId: convertToNumber(
            potentialAgent.collaborativePracticeId
          ),
          emailAddress: potentialAgent.emailAddress,
          commissionSplit: DATA_UNAVAILABLE,
          // Commenting out the method call and display Unavailable as a temporary fix for
          // Servicenet displaying incorrect Commission Information For Advisor
          // commissionSplit: getCommissionSplit({
          //   agent: potentialAgent,
          //   agentSubRoleCode: agentCode,
          // }),
        },
      ];
    }
    return foundAgents;
  }, []);
};

export const getCommissionSplit = ({ agent, agentSubRoleCode }) => {
  const agentObject = agent.agreements.find((agreement) =>
    compareStrings(agreement.subRoleCode, agentSubRoleCode)
  );

  if (
    !isANumber(agentObject?.commissionSplit) ||
    !isDefined(agentObject?.commissionSplit)
  )
    return "Unavailable";

  const formattedCommissionSplit = formatPercentage({
    rate: agentObject.commissionSplit * 100,
    decimalPlaces: 0,
    maxDecimalPlaces: 0,
  });
  return formattedCommissionSplit;
};

export const getUsers = (
  agreementCustomers = [],
  roleType = "",
  nameFormat = nameFormattingOptions.firstNameDisplayedFirst
) => {
  const filteredList = agreementCustomers.filter((customer) =>
    compareStrings(roleType, customer.roleType)
  );
  return filteredList.map((customer) => {
    const formattedName = isDefined(customer)
      ? formatName({
          nameObject: customer,
          format: nameFormat,
        })
      : "";
    return { ...customer, fullName: formattedName };
  });
};

export const getCustomerByMemberId = ({ memberId, agreementData }) => {
  if (!isString(memberId) || !isDefined(agreementData)) {
    return {};
  }

  return (
    agreementData?.agreementCustomers?.find(
      (cust) =>
        compareStrings(memberId, cust.memberId) &&
        compareStrings(cust.roleType, "OWNR")
    ) || {}
  );
};

export const getAdminFromAgreementKey = (agreementKey = "") => {
  const startIndexOfAdmin = agreementKey.lastIndexOf("|");
  return agreementKey.substr(startIndexOfAdmin + 1) || "";
};

export const getPolicyIdFromAgreementKey = (agreementKey = "") => {
  const endOfPolicyId = agreementKey.indexOf("|");
  return agreementKey.substr(0, endOfPolicyId) || "";
};

export const getTrimmedPolicyIdFromAgreement = (agreementKey = "") => {
  const policyId = getPolicyIdFromAgreementKey(agreementKey) || "";
  const trimmedPolicyId = cutPrecedingZerosFromId(policyId);

  return {
    trimmedPolicyId,
  };
};

export const generateAgreementKeyfromAgreementData = ({
  primaryId,
  prefix,
  suffix,
  adminSystemCode,
}) => {
  const formatedSuffix = isDefined(suffix) ? suffix : "";
  const formattedPrefix = isDefined(prefix) ? prefix : "";
  const agreementKey = `${primaryId}|${formattedPrefix}|${formatedSuffix}|${adminSystemCode}`;
  return agreementKey;
};

export const isHomeOfficeUser = (user) =>
  compareArrayOfStrings(user.groups, "fim-servicenet-homeoffice");

export const getBpIdFromUser = (user) => {
  const userName = user?.idToken?.claims?.IdNum;
  const idNumber = userName || "";
  const isAnAgent =
    idNumber?.toLowerCase().includes("aa") ||
    idNumber?.toLowerCase().includes("ae");
  const bpId = idNumber?.substr(2);

  const mmsd =
    user?.idToken?.claims?.groups?.includes("fim-servicenet-mmsd-advisor") &&
    !user?.idToken?.claims?.groups?.includes("fim-servicenet-field");

  const mmsdMmfa =
    user?.idToken?.claims?.groups?.includes("fim-servicenet-mmsd-advisor") &&
    user?.idToken?.claims?.groups?.includes("fim-servicenet-field");

  const mmfa =
    user?.idToken?.claims?.groups?.includes("fim-servicenet-field") &&
    !user?.idToken?.claims?.groups?.includes("fim-servicenet-mmsd-advisor");

  let agentType;

  if (mmsdMmfa) {
    agentType = typesOfAgents.MMSD_MMFA;
  } else if (mmsd) {
    agentType = typesOfAgents.MMSD;
  } else if (mmfa) {
    agentType = typesOfAgents.MMFA;
  }

  return isAnAgent
    ? { isAnAgent, agentType, idNumber, bpId }
    : { isAnAgent, idNumber, bpId: "" };
};

export const getRequestorInfo = (props) => {
  let requestorTypeCode = "J";
  let agencyId = "";
  const { producersData, idNumber, agreementKey } = props;
  const userId = idNumber.substr(2);
  const trimmedUserId = userId.replace(/^0+/, "");
  const trimmedAgreementKey = agreementKey.replace(/^0+/, "");
  producersData?.producers.forEach((producer) => {
    const bpId = producer.businessPartnerId;
    const { agreements } = producer;
    const trimmedBpId = bpId.replace(/^0+/, "");
    if (compareStrings(trimmedUserId, trimmedBpId)) {
      agencyId = producer.homeAgencyId;
      agreements.forEach((agreement) => {
        const trimmedKeyFromProducers = agreement?.agreementKey.replace(
          /^0+/,
          ""
        );
        if (compareStrings(trimmedKeyFromProducers, trimmedAgreementKey)) {
          if (
            (compareStrings(agreement.roleCode, "AGT") &&
              compareArrayOfStrings(["SVC", "WRTG"], agreement.subRoleCode)) ||
            compareStrings(agreement.roleCode, "REP")
          ) {
            requestorTypeCode = "A";
          }
        }
      });
    }
  });
  return { requestorAgentId: idNumber, agencyId, requestorTypeCode };
};

export const getBaseCoverages = (agreementCoverages = []) => {
  // also sorts the base coverages with descending effectivedate.
  const baseCoverages = agreementCoverages
    .filter((coverage) => {
      const { type, category } = coverage;
      return compareStrings(category, "B") && compareStrings(type, "BASE");
    })
    .sort((a, b) => new Date(b.effectiveDate) - new Date(a.effectiveDate));
  return { baseCoverages };
};

export const getRidersByType = (agreementCoverages = [], riderType = "") => {
  const riders = agreementCoverages.filter((coverage) => {
    const { type, category } = coverage;
    return (
      compareStrings(category, "R") &&
      (!riderType || compareStrings(type, riderType))
    );
  });
  return { riders };
};

export const getModalPremiumsForPolicy = (props) => {
  const { billingData, agreementData } = props;
  const {
    paymentPreference,
    modalPremiums = [],
    billFrequency: currentFrequency,
  } = billingData;
  const { agreementKeyAdmin, lineOfBusinessCode } = agreementData;

  const filteredModalPremiums = [];

  modalPremiums.forEach((item) => {
    const {
      billingType,
      billingModeDesc,
      amount,
      annualizedAmount,
      modalCharge,
      apr,
    } = item;

    let isValidBillingMode;
    const isDIPolicy = isDisabilityPolicy({ lineOfBusinessCode });
    if (isDIPolicy) {
      isValidBillingMode = isValidDIModalPremium(
        billingModeDesc,
        billingType,
        agreementKeyAdmin,
        paymentPreference
      );
    } else {
      isValidBillingMode = isValidLifeModalPremium(
        billingModeDesc,
        billingType,
        agreementKeyAdmin,
        paymentPreference
      );
    }

    if (isValidBillingMode) {
      let frequency = billingModeDesc;
      if (compareStrings(BILLING_DESCRIPTION_MONTHLY, billingModeDesc)) {
        frequency = "Monthly (PAC)";
      }
      if (
        isDIPolicy &&
        compareStrings(BILLING_DESCRIPTION_QUARTERLY, billingModeDesc) &&
        agreementKeyAdmin === "EDS-DI"
      ) {
        frequency = "Quarterly (PAC)";
      }

      const isMonthlyButNotPAC =
        compareStrings(BILLING_DESCRIPTION_MONTHLY, billingModeDesc) &&
        paymentPreference !== "PAC";

      const isQuarterlyButNotPACForDI =
        isDIPolicy &&
        compareStrings(BILLING_DESCRIPTION_QUARTERLY, billingModeDesc) &&
        compareStrings(agreementKeyAdmin, "EDS-DI") &&
        paymentPreference !== "PAC";

      // Monthly premium and Non PAC payment preference is valid for diplsay as a premium option ,
      // Quarterly premium and Non PAC payment preference is valid for diplsay as a DI premium option ,
      // but can not display Current badge beside the Monthly/Quarterly data
      const isCurrentBillingMode =
        compareStrings(billingModeDesc, currentFrequency) &&
        !isMonthlyButNotPAC &&
        !isQuarterlyButNotPACForDI;

      const premium = formatMoney(amount, "-");
      const annualPremium = formatMoney(annualizedAmount, "-");
      const modalChargeAmount = formatMoney(modalCharge, "-");
      const aprPercentage = formatPercentage({
        rate: apr,
        maxDecimalPlaces: 2,
      });

      filteredModalPremiums.push({
        billingModeDesc,
        frequency,
        premium,
        annualPremium,
        modalChargeAmount,
        aprPercentage,
        isCurrentBillingMode,
      });
    }
  });
  return filteredModalPremiums;
};

export const getBillingModeData = (modalPremiums) => {
  const defaultBillingData = {
    frequency: "-",
    premium: "-",
    annualPremium: "-",
    modalChargeAmount: "-",
    aprPercentage: "-",
    isCurrentBillingMode: false,
  };
  let monthlyBillingData = {
    ...defaultBillingData,
    frequency: "Monthly (PAC)",
  };
  let quarterlyBillingData = { ...defaultBillingData, frequency: "Quarterly" };
  let semiAnnualBillingData = {
    ...defaultBillingData,
    frequency: "Semi-Annual",
  };
  let annualBillingData = { ...defaultBillingData, frequency: "Annual" };

  modalPremiums?.forEach((item) => {
    switch (item.billingModeDesc) {
      case BILLING_DESCRIPTION_MONTHLY:
        monthlyBillingData = item;
        break;
      case BILLING_DESCRIPTION_QUARTERLY:
        quarterlyBillingData = item;
        break;
      case BILLING_DESCRIPTION_SEMI_ANNUAL:
        semiAnnualBillingData = item;
        break;
      case BILLING_DESCRIPTION_ANNUAL:
        annualBillingData = item;
        break;
      default:
        break;
    }
  });
  return {
    billingModeData: {
      monthlyBillingData,
      quarterlyBillingData,
      semiAnnualBillingData,
      annualBillingData,
    },
  };
};

export const isValidDIModalPremium = (
  billingModeDesc,
  billingType,
  agreementKeyAdmin,
  paymentPreference
) => {
  const isValidSemiAnnualandAnnualBillingMode =
    compareArrayOfStrings(
      [BILLING_DESCRIPTION_SEMI_ANNUAL, BILLING_DESCRIPTION_ANNUAL],
      billingModeDesc
    ) && billingType === "REG";

  const isValidQuerterlyBillingModeForEDSDIPolicy =
    compareStrings(BILLING_DESCRIPTION_QUARTERLY, billingModeDesc) &&
    agreementKeyAdmin === "EDS-DI" &&
    billingType === "PAC";

  const isValidQuerterlyBillingModeForOPMorMPRPolicy =
    compareStrings(BILLING_DESCRIPTION_QUARTERLY, billingModeDesc) &&
    compareArrayOfStrings(["OPM", "MPR"], agreementKeyAdmin) &&
    billingType === "REG";

  const isValidMonthlyBillingModeForOPMorMPRPolicy =
    compareStrings(BILLING_DESCRIPTION_MONTHLY, billingModeDesc) &&
    compareArrayOfStrings(["OPM", "MPR"], agreementKeyAdmin) &&
    ((paymentPreference === "PAC" && billingType === "PAC") ||
      (paymentPreference !== "PAC" && billingType === "REG"));

  const isValidMonthlyBillingModeForEDSDIPolicy =
    compareStrings(BILLING_DESCRIPTION_MONTHLY, billingModeDesc) &&
    agreementKeyAdmin === "EDS-DI" &&
    billingType === "PAC";

  return (
    isValidSemiAnnualandAnnualBillingMode ||
    isValidQuerterlyBillingModeForEDSDIPolicy ||
    isValidQuerterlyBillingModeForOPMorMPRPolicy ||
    isValidMonthlyBillingModeForOPMorMPRPolicy ||
    isValidMonthlyBillingModeForEDSDIPolicy
  );
};

export const isValidLifeModalPremium = (
  billingModeDesc,
  billingType,
  agreementKeyAdmin,
  paymentPreference
) => {
  const isValidNonMonthlyBillingMode =
    compareArrayOfStrings(
      [
        BILLING_DESCRIPTION_QUARTERLY,
        BILLING_DESCRIPTION_SEMI_ANNUAL,
        BILLING_DESCRIPTION_ANNUAL,
      ],
      billingModeDesc
    ) && billingType === "REG";

  const isValidMonthlyBillingModeForMPRPolicy =
    compareStrings(BILLING_DESCRIPTION_MONTHLY, billingModeDesc) &&
    agreementKeyAdmin === "MPR" &&
    billingType === "PAC";

  const isValidMonthlyBillingModeForOPMorVNTG1Policy =
    compareStrings(BILLING_DESCRIPTION_MONTHLY, billingModeDesc) &&
    compareArrayOfStrings(["OPM", "VNTG1"], agreementKeyAdmin) &&
    ((paymentPreference === "PAC" && billingType === "PAC") ||
      (paymentPreference !== "PAC" && billingType === "REG"));

  return (
    isValidNonMonthlyBillingMode ||
    isValidMonthlyBillingModeForMPRPolicy ||
    isValidMonthlyBillingModeForOPMorVNTG1Policy
  );
};

export const getAgentCommunicationDetailsByType = (
  communications,
  communicationType
) => {
  if (isDefined(communications) && communications.length > 0) {
    const result =
      communications.find((item) => item.type === communicationType) || {};

    switch (result.type) {
      case communicationTypes.EMAIL:
        return result.emailAddress;
      case communicationTypes.FAX:
        return result.faxNumber;
      case communicationTypes.PREF:
        return result.phoneNumber;
      default:
        return "";
    }
  } else {
    return "";
  }
};

export const getServiceHistoryRequestsbyEventNameAndStatus = (
  serviceHistoryData,
  status,
  transactionsArray
) => {
  const { eventsArray } = serviceHistoryData;
  return eventsArray?.find(
    (item) =>
      compareStrings(item.status, status) &&
      compareArrayOfStrings(transactionsArray, item.eventName)
  );
};
export const getGroupDetailsForDI = (agreementData) => {
  const {
    groupAccountId,
    agreementKeyAdmin,
    agreementCustomers,
    discountPercent1,
  } = agreementData;

  const isEDSDIPolicy =
    isDisabilityPolicy(agreementData) &&
    compareStrings(agreementKeyAdmin, "EDS-DI");
  const grpRoleType = getUsers(agreementCustomers, "GRP")[0];
  const groupName = grpRoleType?.fullName || "";
  const showGroupDetails = isEDSDIPolicy && !compareStrings(groupName, "");
  const discountPercent = discountPercent1 * 100;

  return { showGroupDetails, groupName, discountPercent, groupAccountId };
};

export const isMmsdOnlyAgent = (user) => {
  const { agentType } = getBpIdFromUser(user);
  return compareStrings(agentType, typesOfAgents.MMSD);
};

export const checkIfUserRegisteredOnMM = (props) => {
  const { customer } = props;
  const isUserRegistered = isRegisteredOnMM(customer);

  const userRegisteredMessageTooltipText = isUserRegistered
    ? "Registered on MassMutual.com"
    : "Not Registered on MassMutual.com";

  const userRegisteredMessage = isUserRegistered
    ? "Registered"
    : "Not Registered";
  return {
    isUserRegistered,
    userRegisteredMessage,
    userRegisteredMessageTooltipText,
  };
};

export const checkIfAtLeastOneUserRegisteredOnMM = (agreementData) => {
  const { agreementCustomers = [] } = agreementData;
  const agreementOWNRs = agreementCustomers.filter((customer) =>
    compareStrings(customer.roleType, "OWNR")
  );
  return agreementOWNRs.some((customer) => isRegisteredOnMM(customer));
};

export const getOwnerFromCustomers = (props) => {
  const { agreementCustomers } = props;

  const owners =
    getUsers(
      agreementCustomers,
      userTypes.OWNER,
      nameFormattingOptions.fullNameThenFirstName
    ) || [];
  const owner = owners?.[0];
  const {
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
  } = formatAddress(owner?.address, false);

  return {
    owner,
    address: {
      attnLine,
      addrFirstLine,
      addrSecondLine,
      addrThirdLine,
      addrFourthLine,
    },
  };
};

export const getLabel = (objArray, value) => {
  const filteredItem = objArray.filter((item) => item.value === value);

  return filteredItem[0]?.label;
};

export const DROPDOWN_ITEM_FINDER = { ID: "ID", VALUE: "VALUE" };

export const getMatchedDropDownOption = (
  options,
  valueToMatch,
  finder = DROPDOWN_ITEM_FINDER.VALUE
) =>
  options?.reduce((foundOption, currentOption) => {
    const alreadyFoundMatch = isDefined(foundOption);
    const foundOneMatch =
      finder === DROPDOWN_ITEM_FINDER.ID
        ? currentOption.id === valueToMatch
        : currentOption.value === valueToMatch;

    if (alreadyFoundMatch) {
      return foundOption;
    }
    if (foundOneMatch) {
      return currentOption;
    }

    return foundOption;
  }, undefined);

export const getRequestorTypeCodeFromUserId = (idNumber) => {
  let requestorTypeCode = "";
  if (idNumber?.toLowerCase().includes("aa")) {
    requestorTypeCode = "A";
  } else if (idNumber?.toLowerCase().includes("ae")) {
    requestorTypeCode = "J";
  }
  return requestorTypeCode;
};

export const getLoggedInUserInfo = (user) => {
  const { firstName = "", lastName = "", idToken = {}, email = "" } = user;

  const { IdNum: idNumber } = idToken?.claims || {};

  const homeOfficeUser = isHomeOfficeUser(user);

  const loggedInUserInfo = {
    firstName,
    lastName,
    email,
    idNumber,
    requestorTypeCode: homeOfficeUser
      ? ""
      : getRequestorTypeCodeFromUserId(idNumber),
  };

  return loggedInUserInfo;
};

export const getPrefixAndSuffixFromAgreementKey = (agreementKey = "") => {
  const agreementKeySplit = agreementKey.split("|");
  return {
    prefix: agreementKeySplit[1] || "",
    suffix: agreementKeySplit[2] || "",
  };
};
export const agreementHasAssignee = (agreementData) => {
  const agreementCustomers = agreementData?.agreementCustomers;
  if (!isDefined(agreementCustomers)) return false;
  return agreementCustomers.some((customer) =>
    compareStrings(customer.roleType, "ASGN")
  );
};

export const getFIORiderData = (data) => {
  if (!data?.agreementCoverages) return {};

  const { agreementCoverages, agreementKeyAdmin } = data;

  const futureInsurabilityOptionRider = agreementCoverages?.find((rider) =>
    compareStrings(
      rider.productEnterpriseName,
      "Future Insurability Option Rider"
    )
  );

  const hasFIORider =
    isObject(futureInsurabilityOptionRider) &&
    !isEmptyObject(futureInsurabilityOptionRider) &&
    compareStrings(agreementKeyAdmin, "EDS-DI");

  const {
    nextExerciseDateFIO,
    faceAmount: faceAmountFIO,
    productEnterpriseName,
  } = futureInsurabilityOptionRider || {};

  return {
    hasFIORider,
    nextExerciseDateFIO: formatDate(nextExerciseDateFIO, "", "-"),
    faceAmountFIO: formatMoney(faceAmountFIO, "-"),
    productEnterpriseName: isDefined(productEnterpriseName)
      ? productEnterpriseName
      : "-",
  };
};
