import {
  DI_OCCUPATION_CODE_TOOL_TIP_FOR_NON_RADIOUS_CHOICE,
  DI_OCCUPATION_CODE_TOOL_TIP_FOR_RADIOUS_CHOICE,
} from "../../../../constants/ui";
import { getBaseCoverages } from "../../../../utils/findData";
import { convertToTitleCase } from "../../../../utils/format";
import {
  isDisabilityPolicy,
  isRadiusChoicePolicy,
} from "../../../../utils/policies";

export const deathBenefitOptionText = (
  agreeKeyAdmin,
  deathBenefitOption,
  defaultReturn = ""
) => {
  switch (agreeKeyAdmin) {
    case "OPM":
    case "MPR":
    case "VNTG1":
    case "VNTG2":
    case undefined:
      return "None";
    default:
      return deathBenefitOption || defaultReturn;
  }
};

export const getOccupationCodeData = (data) => {
  if (!data?.agreementCoverages) return {};

  const { agreementCoverages } = data;
  const { baseCoverages } = getBaseCoverages(agreementCoverages);

  const occupationalCode = baseCoverages[0]?.occupationalRatingClass || "-";
  const occupationalCodeToolTipText = isRadiusChoicePolicy({
    baseCoverage: baseCoverages[0],
  })
    ? DI_OCCUPATION_CODE_TOOL_TIP_FOR_RADIOUS_CHOICE
    : DI_OCCUPATION_CODE_TOOL_TIP_FOR_NON_RADIOUS_CHOICE;
  return { occupationalCode, occupationalCodeToolTipText };
};

export const getProductName = (data) => {
  if (!data) return "-";
  if (isDisabilityPolicy(data)) {
    const { agreementCoverages } = data;
    const { baseCoverages } = getBaseCoverages(agreementCoverages);
    const productName = baseCoverages[0]?.marketingName || "-";
    return convertToTitleCase(productName);
  }

  return data?.productTypeName || "-";
};
