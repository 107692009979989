import React from "react";
import { useDetailProps } from "./hooks";
import {
  checkStatusAndReason,
  checkSpecialConditions,
  checkStatusReasonAndHap,
} from "../../types/life/hooks";
import { stringIsEmptyOrNullish } from "../../../../utils/string";
import IsTruthy from "../../../common/logic/isTruthy";
import IsFalsy from "../../../common/logic/isFalsy";
import {
  RequestInformationLink,
  LabelWithInformationPopover,
  InformationPopover,
} from "../../../common/popovers/informationPopover";
import {
  isTermPolicy,
  isULUVPolicy,
  isTermOrPermPolicy,
  isVariableLife,
  isCC1PolicyWithLTCIR,
  isPE1NTLVL,
  isDisabilityPolicy,
  isPallmPolicy,
} from "../../../../utils/policies";
import { isDefined } from "../../../../utils/evaluate";
import { useHidePageFromUser } from "../../../common/featureFlags/hooks";
import { fireTealiumAndFollowLink } from "../../../../utils/tealium";
import InsuredInformation from "../insuredInformation";
import { ULTIMATE_FACE_AMOUNT_TOOLTIP } from "../../../../constants/ui";

const Detail = (props) => {
  const {
    id,
    policyId,
    agreementKey,
    agreementRequest: { data },
    email,
    agreementRequest,
  } = props;
  const {
    eligibleCashValueValidation,
    displayUnsupportedCashValueMessage,
    data: {
      anniversaryDate,
      autoPremiumLoanIndicator,
      deathBenefitOption,
      faceAmount,
      jurisdictionState,
      lineOfBusinessName,
      mecIndicator,
      netDeathBenefitAmount,
      netSurrenderAmount,
      forfeitureCodeDisplay,
      partialSurrenderAmtDisplay,
      productName,
      qualifiedIndicator,
      requestedMaturityDate,
      status,
      formattedNetDeathBenefitAmount,
      formattedNetSurrenderAmount,
      formattedNetSurrenderAmountForLtcir,
      totalAccountValue,
      ltcResidualDeathBenefitAmount,
      ultimateFaceAmount,
      displayResidualDeathBenefit,
      displayUltimateFaceAmount,
      convertiblilityStatus,
      convertibilityExpDate,
      monthlyBenefitMaximumAmount,
      occupationalCode,
      series,
      occupationalCodeToolTipText,
      hasFIORider,
      nextExerciseDateFIO,
      faceAmountFIO,
    },
    isPallmNTLPolicy,
    issueDateValue,
    isEligibleForCashValueQuoteLink,
  } = useDetailProps(props);

  const {
    agreementKeyAdmin,
    productTypeCode,
    majorProductCode,
    minorProductCode,
    majorProductName,
  } = data;

  const hideLinksToFundsPage = useHidePageFromUser({
    pageName: "fundPage",
    agreementKeyAdmin,
    productTypeCode,
    majorProductCode,
    minorProductCode,
  });

  return (
    <div className="mm-spacing">
      <h2 aria-label="Policy Details" id={id}>
        Policy Details
      </h2>
      <div className="mm-spacing--minor">
        <hr />
        <div className="row mm-spacing--minor">
          <div className="col-12 col-md-6">
            <dl className="mm-key-value-pair">
              <dt id="product_name_lbl">Product Name</dt>
              <dd id="product_name_value">{productName}</dd>
              <IsTruthy value={data} validationFunction={isDisabilityPolicy}>
                <dt id="product_type_lbl">Product Type</dt>
                <dd id="product_type_value">{majorProductName}</dd>
              </IsTruthy>
              <dt id="product_line_lbl">Product Line</dt>
              <dd id="product_line_value">{lineOfBusinessName}</dd>
              <dt id="policy_id_lbl">Policy Number</dt>
              <dd id="policy_id_value">{policyId}</dd>
              <IsFalsy value={data} validationFunction={isDisabilityPolicy}>
                <dt id="policy_status_lbl">Status</dt>
                <dd id="policy_status_value">{status}</dd>
              </IsFalsy>
              <IsFalsy value={data} validationFunction={isDisabilityPolicy}>
                <dt id="policy_face_amount_lbl">Face Amount</dt>
                <dd id="policy_face_amount_value">{faceAmount}</dd>{" "}
                <IsFalsy value={data} validationFunction={isPallmPolicy}>
                  <dt id="policy_death_benefit_lbl">
                    <IsTruthy
                      value={netDeathBenefitAmount}
                      validationFunction={stringIsEmptyOrNullish}
                    >
                      <IsTruthy
                        value={{ data }}
                        validationFunction={checkStatusReasonAndHap}
                      >
                        <LabelWithInformationPopover
                          label="Est. Death Benefit"
                          popoverId="policy_death_benefit"
                          text="The information is not available online or via the call center. Click to submit a request for this information."
                        />
                      </IsTruthy>
                      <IsFalsy
                        value={{ data }}
                        validationFunction={checkStatusReasonAndHap}
                      >
                        Est. Death Benefit
                      </IsFalsy>
                    </IsTruthy>
                    <IsFalsy
                      value={netDeathBenefitAmount}
                      validationFunction={stringIsEmptyOrNullish}
                    >
                      Est. Death Benefit
                    </IsFalsy>
                  </dt>
                  <dd id="policy_death_benefit_value">
                    <IsTruthy
                      value={netDeathBenefitAmount}
                      validationFunction={stringIsEmptyOrNullish}
                    >
                      <IsTruthy
                        value={{ data }}
                        validationFunction={checkStatusReasonAndHap}
                      >
                        <RequestInformationLink
                          popoverId="policy_death_benefit"
                          agreementData={data}
                          agreementKey={agreementKey}
                          policyId={policyId}
                        />
                      </IsTruthy>
                      <IsFalsy
                        value={{ data }}
                        validationFunction={checkStatusReasonAndHap}
                      >
                        {formattedNetDeathBenefitAmount}
                      </IsFalsy>
                    </IsTruthy>
                    <IsFalsy
                      value={netDeathBenefitAmount}
                      validationFunction={stringIsEmptyOrNullish}
                    >
                      {formattedNetDeathBenefitAmount}
                    </IsFalsy>
                  </dd>
                </IsFalsy>
                <IsTruthy value={data} validationFunction={isPallmPolicy}>
                  <dt id="policy_death_benefit_lbl">Est. Death Benefit</dt>
                  <dd id="policy_death_benefit_value">Unavailable</dd>
                </IsTruthy>
              </IsFalsy>
              <IsTruthy value={props} validationFunction={isTermPolicy}>
                <dt id="policy_convertibility_exp_status_lbl">
                  Convertibility Exp. Date
                </dt>
                <dd id="policy_converibility_exp_date_value">
                  {convertibilityExpDate}
                </dd>
              </IsTruthy>
              <IsTruthy value={isPallmNTLPolicy}>
                <dt id="policy_partial_surrender_value_lbl">
                  Partial Surrender Value
                </dt>
                <dd id="policy_partial_surrender_value">Unavailable</dd>
              </IsTruthy>
            </dl>
          </div>
          <div className="col-12 col-md-6">
            <dl className="mm-key-value-pair">
              <IsTruthy value={data} validationFunction={isDisabilityPolicy}>
                <dt id="policy_status_lbl">Status</dt>
                <dd id="policy_status_value">{status}</dd>
                <dt id="policy_policy_date_lbl">Policy Date</dt>
                <dd id="policy_policy_date_value">{issueDateValue}</dd>
              </IsTruthy>
              <IsFalsy value={data} validationFunction={isDisabilityPolicy}>
                <dt id="policy_issue_date_lbl">Issue Date</dt>
                <dd id="policy_issue_date_value">{issueDateValue}</dd>
              </IsFalsy>
              <dt id="policy_anniversary_date_lbl">Anniversary Date</dt>
              <dd id="policy_anniversary_date_value">{anniversaryDate}</dd>
              <IsTruthy value={data} validationFunction={isDisabilityPolicy}>
                <dt id="policy_monthly_benefit_maximum_amount_lbl">
                  Monthly Benefit for Total Disability
                </dt>
                <dd id="policy_monthly_benefit_maximum_amount_value">
                  {monthlyBenefitMaximumAmount}
                </dd>
              </IsTruthy>
              <IsFalsy value={data} validationFunction={isDisabilityPolicy}>
                <dt id="policy_maturity_date_lbl">Maturity Date</dt>
                <dd id="policy_maturity_date_value">
                  <IsTruthy
                    value={requestedMaturityDate}
                    validationFunction={isDefined}
                  >
                    {requestedMaturityDate}
                  </IsTruthy>
                  <IsFalsy
                    value={requestedMaturityDate}
                    validationFunction={isDefined}
                  >
                    N/A
                  </IsFalsy>
                </dd>
                <IsTruthy value={props} validationFunction={isULUVPolicy}>
                  <dt id="account_value_lbl">
                    <IsTruthy
                      value={totalAccountValue}
                      validationFunction={stringIsEmptyOrNullish}
                    >
                      <IsTruthy
                        value={data}
                        validationFunction={checkStatusAndReason}
                      >
                        <LabelWithInformationPopover
                          label="Account Value"
                          popoverId="account_value"
                          text="The information is not available online or via the call center. Click to submit a request for this information."
                        />
                      </IsTruthy>
                      <IsFalsy
                        value={data}
                        validationFunction={checkStatusAndReason}
                      >
                        Account Value
                      </IsFalsy>
                    </IsTruthy>
                    <IsFalsy
                      value={totalAccountValue}
                      validationFunction={stringIsEmptyOrNullish}
                    >
                      Account Value
                    </IsFalsy>
                  </dt>
                  <dd id="account_value_value">
                    <IsTruthy
                      value={totalAccountValue}
                      validationFunction={stringIsEmptyOrNullish}
                    >
                      <IsTruthy
                        value={data}
                        validationFunction={checkStatusAndReason}
                      >
                        <RequestInformationLink
                          popoverId="account_value_info"
                          agreementData={data}
                          agreementKey={agreementKey}
                          policyId={policyId}
                        />
                      </IsTruthy>
                      <IsFalsy
                        value={data}
                        validationFunction={checkStatusAndReason}
                      >
                        Unavailable
                      </IsFalsy>
                    </IsTruthy>
                    <IsFalsy
                      value={totalAccountValue}
                      validationFunction={stringIsEmptyOrNullish}
                    >
                      {totalAccountValue}
                    </IsFalsy>
                  </dd>
                </IsTruthy>
                <IsTruthy value={isPallmNTLPolicy}>
                  <dt id="policy_account_value_lbl">Account Value</dt>
                  <dd id="policy_account_value">Unavailable</dd>
                </IsTruthy>
                <IsFalsy value={props} validationFunction={isULUVPolicy}>
                  <dt id="policy_net_cash_lbl">
                    <IsTruthy
                      value={netSurrenderAmount}
                      validationFunction={stringIsEmptyOrNullish}
                    >
                      <IsTruthy value={props} validationFunction={isTermPolicy}>
                        Net Cash Value
                      </IsTruthy>
                      <IsFalsy value={props} validationFunction={isTermPolicy}>
                        <IsTruthy
                          value={data}
                          validationFunction={checkStatusAndReason}
                        >
                          <IsTruthy
                            value={data}
                            validationFunction={checkSpecialConditions}
                          >
                            <LabelWithInformationPopover
                              label="Net Cash Value"
                              popoverId="detail_net_cash"
                              text="The information is not available online or via the call center. Click to submit a request for this information."
                            />
                          </IsTruthy>
                          <IsFalsy
                            value={data}
                            validationFunction={checkSpecialConditions}
                          >
                            <LabelWithInformationPopover
                              label="Net Cash Value"
                              popoverId="detail_net_cash"
                              text="Information is not available online for this policy because Long Term Care Rider claim payments exist. Please contact the call center to request a quote."
                            />
                          </IsFalsy>
                        </IsTruthy>
                        <IsFalsy
                          value={data}
                          validationFunction={checkStatusAndReason}
                        >
                          Net Cash Value
                        </IsFalsy>
                      </IsFalsy>
                    </IsTruthy>
                    <IsFalsy
                      value={netSurrenderAmount}
                      validationFunction={stringIsEmptyOrNullish}
                    >
                      Net Cash Value
                    </IsFalsy>
                  </dt>
                  <dd id="policy_net_cash_value">
                    <IsTruthy value={props} validationFunction={isTermPolicy}>
                      Not Applicable
                    </IsTruthy>
                    <IsFalsy value={props} validationFunction={isTermPolicy}>
                      <IsTruthy
                        value={netSurrenderAmount}
                        validationFunction={stringIsEmptyOrNullish}
                      >
                        <IsTruthy
                          value={data}
                          validationFunction={checkStatusAndReason}
                        >
                          <IsTruthy
                            value={data}
                            validationFunction={checkSpecialConditions}
                          >
                            <RequestInformationLink
                              popoverId="detail_net_cash"
                              agreementData={data}
                              agreementKey={agreementKey}
                              policyId={policyId}
                            />
                          </IsTruthy>
                          <IsFalsy
                            value={data}
                            validationFunction={checkSpecialConditions}
                          >
                            Unavailable
                          </IsFalsy>
                        </IsTruthy>
                        <IsFalsy
                          value={data}
                          validationFunction={checkStatusAndReason}
                        >
                          Unavailable
                        </IsFalsy>
                      </IsTruthy>
                      <IsFalsy
                        value={netSurrenderAmount}
                        validationFunction={stringIsEmptyOrNullish}
                      >
                        {formattedNetSurrenderAmount}
                      </IsFalsy>
                    </IsFalsy>
                  </dd>
                </IsFalsy>
                <IsTruthy value={props} validationFunction={isULUVPolicy}>
                  <dt id="policy_surrender_amount_lbl">Surrender Value</dt>
                </IsTruthy>
                <IsFalsy value={props} validationFunction={isULUVPolicy}>
                  <IsTruthy
                    value={props}
                    validationFunction={isCC1PolicyWithLTCIR}
                  >
                    <dt id="policy_surrender_amount_cc1_lbl">
                      <IsTruthy
                        value={data}
                        validationFunction={checkSpecialConditions}
                      >
                        <LabelWithInformationPopover
                          label="Surrender Value"
                          popoverId="detail_surrender"
                          text="The displayed Surrender Value includes the LTCIR Partial Return of Rider Premium."
                        />
                      </IsTruthy>
                      <IsFalsy
                        value={data}
                        validationFunction={checkSpecialConditions}
                      >
                        <LabelWithInformationPopover
                          label="Surrender Value"
                          popoverId="detail_surrender"
                          text="Information is not available online for this policy because Long Term Care Rider claim payments exist. Please contact the call center to request a quote."
                        />
                      </IsFalsy>
                    </dt>
                  </IsTruthy>
                  <IsFalsy
                    value={props}
                    validationFunction={isCC1PolicyWithLTCIR}
                  >
                    <dt id="policy_surrender_amount_lbl">
                      <IsTruthy
                        value={netSurrenderAmount}
                        validationFunction={stringIsEmptyOrNullish}
                      >
                        <IsTruthy
                          value={props}
                          validationFunction={isTermPolicy}
                        >
                          Surrender Value
                        </IsTruthy>
                        <IsFalsy
                          value={props}
                          validationFunction={isTermPolicy}
                        >
                          <IsFalsy value={isPallmNTLPolicy}>
                            <IsTruthy
                              value={data}
                              validationFunction={checkStatusAndReason}
                            >
                              <IsTruthy
                                value={data}
                                validationFunction={checkSpecialConditions}
                              >
                                <LabelWithInformationPopover
                                  label="Surrender Value"
                                  popoverId="detail_surrender"
                                  text="The information is not available online or via the call center. Click to submit a request for this information."
                                />
                              </IsTruthy>
                              <IsFalsy
                                value={data}
                                validationFunction={checkSpecialConditions}
                              >
                                <LabelWithInformationPopover
                                  label="Surrender Value"
                                  popoverId="detail_surrender"
                                  text="Information is not available online for this policy because Long Term Care Rider claim payments exist. Please contact the call center to request a quote."
                                />
                              </IsFalsy>
                            </IsTruthy>
                          </IsFalsy>
                          <IsTruthy value={isPallmNTLPolicy}>
                            Surrender Value
                          </IsTruthy>

                          <IsFalsy
                            value={data}
                            validationFunction={checkStatusAndReason}
                          >
                            Surrender Value
                          </IsFalsy>
                        </IsFalsy>
                      </IsTruthy>
                      <IsFalsy
                        value={netSurrenderAmount}
                        validationFunction={stringIsEmptyOrNullish}
                      >
                        Surrender Value
                      </IsFalsy>
                    </dt>
                  </IsFalsy>
                </IsFalsy>
                <dd id="policy_surrender_amount_value">
                  <IsTruthy value={props} validationFunction={isTermPolicy}>
                    <IsFalsy value={data} validationFunction={isPallmPolicy}>
                      Not Applicable
                    </IsFalsy>
                  </IsTruthy>
                  <IsTruthy value={data} validationFunction={isPallmPolicy}>
                    <IsTruthy value={isPallmNTLPolicy}>
                      {formattedNetSurrenderAmount}
                    </IsTruthy>
                    <IsFalsy value={isPallmNTLPolicy}>Unavailable</IsFalsy>
                  </IsTruthy>
                  <IsFalsy value={props} validationFunction={isTermPolicy}>
                    <IsTruthy
                      value={props}
                      validationFunction={isCC1PolicyWithLTCIR}
                    >
                      <IsFalsy
                        value={formattedNetSurrenderAmountForLtcir}
                        validationFunction={stringIsEmptyOrNullish}
                      >
                        {formattedNetSurrenderAmountForLtcir}
                      </IsFalsy>
                      <IsTruthy
                        value={formattedNetSurrenderAmountForLtcir}
                        validationFunction={stringIsEmptyOrNullish}
                      >
                        <IsTruthy
                          value={data}
                          validationFunction={checkStatusAndReason}
                        >
                          <IsTruthy
                            value={data}
                            validationFunction={checkSpecialConditions}
                          >
                            <RequestInformationLink
                              popoverId="detail_surrender"
                              agreementData={data}
                              agreementKey={agreementKey}
                              policyId={policyId}
                            />
                          </IsTruthy>
                        </IsTruthy>
                      </IsTruthy>
                    </IsTruthy>
                    <IsFalsy
                      value={props}
                      validationFunction={isCC1PolicyWithLTCIR}
                    >
                      <IsTruthy
                        value={netSurrenderAmount}
                        validationFunction={stringIsEmptyOrNullish}
                      >
                        <IsTruthy value={isPallmNTLPolicy}>
                          Unavailable
                        </IsTruthy>

                        <IsTruthy
                          value={data}
                          validationFunction={checkStatusAndReason}
                        >
                          <IsTruthy
                            value={data}
                            validationFunction={checkSpecialConditions}
                          >
                            <IsFalsy value={isPallmNTLPolicy}>
                              <RequestInformationLink
                                popoverId="detail_surrender"
                                agreementData={data}
                                agreementKey={agreementKey}
                                policyId={policyId}
                              />
                            </IsFalsy>
                          </IsTruthy>
                          <IsFalsy
                            value={data}
                            validationFunction={checkSpecialConditions}
                          >
                            Unavailable
                          </IsFalsy>
                        </IsTruthy>
                        <IsFalsy
                          value={data}
                          validationFunction={checkStatusAndReason}
                        >
                          Unavailable
                        </IsFalsy>
                      </IsTruthy>
                      <IsFalsy value={data} validationFunction={isPallmPolicy}>
                        <IsFalsy
                          value={netSurrenderAmount}
                          validationFunction={stringIsEmptyOrNullish}
                        >
                          {formattedNetSurrenderAmount}
                        </IsFalsy>
                      </IsFalsy>
                    </IsFalsy>
                  </IsFalsy>
                </dd>
                <IsTruthy value={props} validationFunction={isTermPolicy}>
                  <dt id="policy_convertibility_status_lbl">
                    Convertibility Status
                  </dt>
                  <dd id="policy_converibility_status_value">
                    {convertiblilityStatus}
                  </dd>
                </IsTruthy>
                <IsTruthy value={displayResidualDeathBenefit}>
                  <dt id="policy_residual_death_benefit_amount_lbl">
                    <LabelWithInformationPopover
                      label="Residual Death Benefit"
                      popoverId="residual_death_benefit_tooltip"
                      text="The Residual Death Benefit is the portion of the total Base face amount that will not be included in the Base LTC Benefit Pool. This amount is elected at issue and may not be changed post issue."
                    />
                  </dt>
                  <dd id="policy_residual_death_benefit_amount_val">
                    <IsTruthy
                      value={ltcResidualDeathBenefitAmount}
                      validationFunction={isDefined}
                    >
                      {ltcResidualDeathBenefitAmount}
                    </IsTruthy>
                    <IsFalsy
                      value={ltcResidualDeathBenefitAmount}
                      validationFunction={isDefined}
                    >
                      Unavailable
                    </IsFalsy>
                  </dd>
                </IsTruthy>
                <IsTruthy value={displayUltimateFaceAmount}>
                  <dt id="details_policy_ultimate_face_amount_lbl">
                    Ultimate Face Amount{" "}
                    <InformationPopover
                      popoverId="details_ultimate_face_amount_tooltip"
                      text={ULTIMATE_FACE_AMOUNT_TOOLTIP}
                    />
                    <sup className="print-only">1</sup>
                  </dt>
                  <dd id="details_policy_ultimate_face_amount_val">
                    <IsTruthy
                      value={ultimateFaceAmount}
                      validationFunction={isDefined}
                    >
                      {ultimateFaceAmount}
                    </IsTruthy>
                    <IsFalsy
                      value={ultimateFaceAmount}
                      validationFunction={isDefined}
                    >
                      Unavailable
                    </IsFalsy>
                  </dd>
                </IsTruthy>
                <IsTruthy value={props} validationFunction={isULUVPolicy}>
                  <dt id="partial_surrender_amount_lbl">
                    <IsTruthy value={props} validationFunction={isPE1NTLVL}>
                      <IsTruthy
                        value={data}
                        validationFunction={checkStatusAndReason}
                      >
                        <IsTruthy
                          value={partialSurrenderAmtDisplay}
                          validationFunction={stringIsEmptyOrNullish}
                        >
                          <LabelWithInformationPopover
                            label="Partial Surrender Value"
                            popoverId="partial_surrender_value"
                            text="The information is not available online or via the call center. Click to submit a request for this information."
                          />
                        </IsTruthy>
                        <IsFalsy
                          value={partialSurrenderAmtDisplay}
                          validationFunction={stringIsEmptyOrNullish}
                        >
                          Partial Surrender Value
                        </IsFalsy>
                      </IsTruthy>
                      <IsFalsy
                        value={data}
                        validationFunction={checkStatusAndReason}
                      >
                        Partial Surrender Value
                      </IsFalsy>
                    </IsTruthy>
                    <IsFalsy value={props} validationFunction={isPE1NTLVL}>
                      <IsTruthy
                        value={data}
                        validationFunction={checkStatusAndReason}
                      >
                        <LabelWithInformationPopover
                          label="Partial Surrender Value"
                          popoverId="partial_surrender_value"
                          text="The information is not available online or via the call center. Click to submit a request for this information."
                        />
                      </IsTruthy>
                      <IsFalsy
                        value={data}
                        validationFunction={checkStatusAndReason}
                      >
                        Partial Surrender Value
                      </IsFalsy>
                    </IsFalsy>
                  </dt>
                  <dd id="partial_surrender_amount_value">
                    <IsTruthy value={props} validationFunction={isPE1NTLVL}>
                      <IsTruthy
                        value={data}
                        validationFunction={checkStatusAndReason}
                      >
                        <IsTruthy
                          value={partialSurrenderAmtDisplay}
                          validationFunction={stringIsEmptyOrNullish}
                        >
                          <RequestInformationLink
                            popoverId="partial_surrender_amount_value"
                            agreementData={data}
                            agreementKey={agreementKey}
                            policyId={policyId}
                          />
                        </IsTruthy>
                        <IsFalsy
                          value={partialSurrenderAmtDisplay}
                          validationFunction={stringIsEmptyOrNullish}
                        >
                          {partialSurrenderAmtDisplay}
                        </IsFalsy>
                      </IsTruthy>
                      <IsFalsy
                        value={data}
                        validationFunction={checkStatusAndReason}
                      >
                        Unavailable
                      </IsFalsy>
                    </IsTruthy>
                    <IsFalsy value={props} validationFunction={isPE1NTLVL}>
                      <IsTruthy
                        value={data}
                        validationFunction={checkStatusAndReason}
                      >
                        <RequestInformationLink
                          popoverId="partial_surrender_amount_value"
                          agreementData={data}
                          agreementKey={agreementKey}
                          policyId={policyId}
                        />
                      </IsTruthy>
                      <IsFalsy
                        value={data}
                        validationFunction={checkStatusAndReason}
                      >
                        Unavailable
                      </IsFalsy>
                    </IsFalsy>
                  </dd>
                </IsTruthy>
              </IsFalsy>
            </dl>
          </div>
        </div>
        <IsFalsy value={data} validationFunction={isDisabilityPolicy}>
          <IsFalsy
            value={props}
            validationFunction={displayUnsupportedCashValueMessage}
          >
            <IsTruthy
              value={props}
              validationFunction={eligibleCashValueValidation}
            >
              <IsTruthy
                value={data}
                validationFunction={checkSpecialConditions}
              >
                <p className="hide-print">
                  <IsFalsy value={isEligibleForCashValueQuoteLink}>
                    For month-end Cash Values,{" "}
                  </IsFalsy>
                  <IsTruthy value={isEligibleForCashValueQuoteLink}>
                    To view month-end cash values or if eligible download a
                    current cash value quote,{" "}
                  </IsTruthy>
                  <button
                    id="view_cashValues_link"
                    className="btn btn-inline"
                    type="button"
                    onClick={() =>
                      fireTealiumAndFollowLink({
                        url: `/policyDetails/${encodeURIComponent(
                          agreementKey
                        )}/cashValues`,
                        action: "Cash Values History link clicked",
                        category: "PDP",
                        value: policyId,
                        custId: email,
                      })
                    }
                  >
                    <IsFalsy value={isEligibleForCashValueQuoteLink}>
                      see Cash Value History.
                    </IsFalsy>
                    <IsTruthy value={isEligibleForCashValueQuoteLink}>
                      see Cash Value Quotes.
                    </IsTruthy>
                  </button>
                </p>
              </IsTruthy>
            </IsTruthy>
          </IsFalsy>
          <IsTruthy value={data} validationFunction={checkStatusAndReason}>
            <IsTruthy
              value={props}
              validationFunction={displayUnsupportedCashValueMessage}
            >
              <div className="alert alert-info hide-print">
                Month-End Cash Values are not supported for this product. Please
                click to submit a request for this information.
                <RequestInformationLink
                  popoverId="unsupported_cash_value"
                  agreementData={data}
                  agreementKey={agreementKey}
                  policyId={policyId}
                />
              </div>
            </IsTruthy>
          </IsTruthy>
        </IsFalsy>
        <IsTruthy value={data} validationFunction={isVariableLife}>
          <IsFalsy value={hideLinksToFundsPage}>
            <p className="hide-print" id="additional_funds_info_text">
              For additional funds information,{" "}
              <button
                className="btn btn-inline"
                id="see_funds_link"
                type="button"
                onClick={() =>
                  fireTealiumAndFollowLink({
                    url: `/policyDetails/${encodeURIComponent(
                      agreementKey
                    )}/funds`,
                    action: "See Funds link clicked",
                    category: "PDP",
                    value: policyId,
                    custId: email,
                  })
                }
              >
                see Funds.
              </button>
            </p>
          </IsFalsy>
        </IsTruthy>
        <div id="print_only_footnotes" className="print-only">
          <IsTruthy value={displayUltimateFaceAmount}>
            <div>1. {ULTIMATE_FACE_AMOUNT_TOOLTIP}</div>
          </IsTruthy>
        </div>
        <hr />
      </div>
      <IsTruthy value={hasFIORider}>
        <div className="mm-spacing--minor">
          <h3 id="future_insurability_option_header">
            Future Insurability Option (FIO)
          </h3>
          <hr />
          <div className="row">
            <div className="col-12 col-md-6">
              <dl className="mm-key-value-pair">
                <dt id="policy_next_fio_exercise_date_lbl">
                  Next Yearly Base FIO Exercise Date
                </dt>
                <dd id="policy_next_fio_exercise_date_value">
                  {nextExerciseDateFIO}
                </dd>
              </dl>
            </div>
            <div className="col-12 col-md-6">
              <dl className="mm-key-value-pair">
                <dt id="policy_remaining_base_fio_amount_lbl">
                  Remaining Base FIO Amount
                </dt>
                <dd id="policy_remaining_base_fio_amount_value">
                  {faceAmountFIO}
                </dd>
              </dl>
            </div>
          </div>
          <hr />
        </div>
      </IsTruthy>
      <div className="mm-spacing--minor">
        <h3 id="policy_design_header">Policy Design</h3>
        <hr />
        <div className="row">
          <div className="col-12 col-md-6">
            <dl className="mm-key-value-pair">
              <IsFalsy value={data} validationFunction={isDisabilityPolicy}>
                <IsTruthy value={props} validationFunction={isTermPolicy}>
                  <dt id="policy_contract_state_lbl">Contract State</dt>
                  <dd id="policy_contract_state_value">{jurisdictionState}</dd>
                </IsTruthy>
                <IsFalsy value={props} validationFunction={isTermPolicy}>
                  <dt id="policy_mec_lbl">MEC</dt>
                  <dd id="policy_mec_value">{mecIndicator}</dd>
                </IsFalsy>
                <IsFalsy value={props} validationFunction={isTermPolicy}>
                  <dt id="policy_non_forfeiture_lbl">Non-Forfeiture Option</dt>
                  <dd id="policy_non_forfeiture_value">
                    {forfeitureCodeDisplay}
                  </dd>
                </IsFalsy>
                <IsFalsy value={props} validationFunction={isTermPolicy}>
                  <dt id="policy_automatic_premium_loan_lbl">
                    Automatic Premium Loan
                  </dt>
                  <dd id="policy_automatic_premium_loan_value">
                    <IsTruthy
                      value={autoPremiumLoanIndicator}
                      validationFunction={isDefined}
                    >
                      {autoPremiumLoanIndicator}
                    </IsTruthy>
                    <IsFalsy
                      value={autoPremiumLoanIndicator}
                      validationFunction={isDefined}
                    >
                      Unavailable
                    </IsFalsy>
                  </dd>
                </IsFalsy>
              </IsFalsy>
              <IsTruthy value={data} validationFunction={isDisabilityPolicy}>
                <dt id="policy_occupation_code_lbl">
                  {" "}
                  <LabelWithInformationPopover
                    label="Occupation Class"
                    popoverId="policy_occupation_code"
                    text={occupationalCodeToolTipText}
                  />
                </dt>
                <dd id="policy_occupation_code_value">
                  {occupationalCode}
                  <sup className="print-only">1</sup>
                </dd>
                <dt id="policy_series_code_lbl">Series</dt>
                <dd id="policy_series_code_value">{series}</dd>
              </IsTruthy>
            </dl>
          </div>
          <div className="col-12 col-md-6">
            <dl className="mm-key-value-pair">
              <IsFalsy value={props} validationFunction={isTermPolicy}>
                <dt id="policy_contract_state_lbl">Contract State</dt>
                <dd id="policy_contract_state_value">{jurisdictionState}</dd>
              </IsFalsy>
              <IsFalsy value={data} validationFunction={isDisabilityPolicy}>
                <dt id="policy_qualified_lbl">Qualified</dt>
                <dd id="policy_qualified_value">
                  <IsTruthy
                    value={qualifiedIndicator}
                    validationFunction={isDefined}
                  >
                    {qualifiedIndicator}
                  </IsTruthy>
                  <IsFalsy
                    value={qualifiedIndicator}
                    validationFunction={isDefined}
                  >
                    No
                  </IsFalsy>
                </dd>

                <IsFalsy value={props} validationFunction={isTermOrPermPolicy}>
                  <dt id="policy_death_benefit_option_lbl">
                    Death Benefit Option
                  </dt>
                  <dd id="policy_death_benefit_option_value">
                    {deathBenefitOption}
                  </dd>
                </IsFalsy>
              </IsFalsy>
            </dl>
          </div>
        </div>
        <IsTruthy value={data} validationFunction={isDisabilityPolicy}>
          <ol
            id="printpreview_policydetails_occupationclass"
            className="print-only"
          >
            <li>{occupationalCodeToolTipText}</li>
          </ol>
        </IsTruthy>
        <hr />
        <InsuredInformation agreementRequest={agreementRequest} />
      </div>
    </div>
  );
};

export default React.memo(Detail);
