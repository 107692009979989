import { agreementHasAssignee, getUsers } from "../../utils/findData";
import {
  checkIfTransactionIsAllowed,
  isEnvisionPolicy,
  isNonEnvisionAnnuity,
  isPolicyInForce,
} from "../../utils/policies";
import { compareArrayOfStrings, compareStrings } from "../../utils/string";
import { supportedTransactions } from "../../utils/translate";
import { useLDValue } from "../common/featureFlags/hooks";

export const useFillableFormList = (props) => {
  const { agreementData } = props;
  const { lineOfBusinessCode } = agreementData;
  const fillableFormsConfig = useLDValue({
    flagName: ["fillableForms"],
  });
  const { forms: formList } = fillableFormsConfig;
  const assigneeOnAgreement = agreementHasAssignee(agreementData);
  const isEnvisionAnnuityContract = isEnvisionPolicy(agreementData);
  const isNonEnvisionAnnuityContract = isNonEnvisionAnnuity({
    data: agreementData,
  });
  return (
    formList?.filter((form) => {
      const isFormOfSameLineOfBusiness = compareStrings(
        form.lineOfBusinessCode,
        lineOfBusinessCode
      );
      const isAgreementWithAssigneeEvaluationSuccess =
        !assigneeOnAgreement || compareStrings(form.code, "BENE-CHANGE-LIFE");

      const isNotAnnuityPartialWithdrawForm = !compareArrayOfStrings(
        ["PARTIAL-WITHDRAW-ANN-ENVISION", "PARTIAL-WITHDRAW-ANN-NON-ENVISION"],
        form.code
      );

      const isAnnuityPartialWithdrawFormEvaluationSuccess =
        isNotAnnuityPartialWithdrawForm ||
        (compareStrings(form.code, "PARTIAL-WITHDRAW-ANN-ENVISION") &&
          isEnvisionAnnuityContract) ||
        (compareStrings(form.code, "PARTIAL-WITHDRAW-ANN-NON-ENVISION") &&
          isNonEnvisionAnnuityContract);

      return (
        isFormOfSameLineOfBusiness &&
        isAgreementWithAssigneeEvaluationSuccess &&
        isAnnuityPartialWithdrawFormEvaluationSuccess
      );
    }) || []
  );
};

export const verifyFillableFormLibraryLink = (props) => {
  const { agreementData, transactionSupportedPolicies, fillableFormList } =
    props;

  const { isTransactionAllowed } = checkIfTransactionIsAllowed({
    transactionSupportedPolicies,
    transactionName: supportedTransactions.FillableFormLinks,
    agreementData,
  });

  const returnObject = {
    isEligible: isAgreementDataEligibleForFormLibraryLink({
      fillableFormList,
      agreementData,
    }),
    isTransactionAllowed,
  };

  return returnObject;
};

export const isAgreementDataEligibleForFormLibraryLink = (props) => {
  const { agreementData, fillableFormList } = props;
  const { status } = agreementData;
  const isValidPolicyStatus = isPolicyInForce(status);

  const { agreementCustomers } = agreementData;

  const owners = getUsers(agreementCustomers, "OWNR") || [];

  const atLeastOneFillableForm = fillableFormList.length > 0;

  const isSingleIndividualOwned =
    owners.length === 1 &&
    owners[0].type === "I" &&
    owners[0].roleSubType === "LIST";

  return (
    isValidPolicyStatus && isSingleIndividualOwned && atLeastOneFillableForm
  );
};
