import React from "react";
import { nameCase, setOptions } from "@foundernest/namecase";
import { compareDesc } from "date-fns";
import { isNaN, isDefined, isObject, isEmptyObject } from "./evaluate";
/* eslint-disable camelcase */
import {
  stringIsEmptyOrNullish,
  isString,
  isNonEmptyString,
  compareStrings,
  compareArrayOfStrings,
  removeAllWhiteSpace,
} from "./string";
import { isNumber } from "./number";
import getCountryCode from "./countryCodes";
import { formatDate, createDateObject } from "./dates";
import BlankPDF from "../api/sample.pdf";
import { states } from "../data/states";
import {
  isFlexExtraAnnuityProduct,
  isFlexIVFund1FPOrFund4FP,
  isFoundationFlexiblePremium,
  isFoundationSinglePremium,
  isOdysseyAnnuityProduct,
  isStableVoyageAnnuityProduct,
  removeOwnerAndInsuredDuplicates,
} from "./policies";
import { hasKey } from "./object";

const capitalize = (s) => {
  if (!isString(s) || stringIsEmptyOrNullish(s)) return "";
  const word = s.toLowerCase();
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const formatMiddleName = (middleName) => {
  if (!isString(middleName) || stringIsEmptyOrNullish(middleName)) return "";
  const IS_MIDDLE_INITIAL = middleName.trim().length === 1;
  return IS_MIDDLE_INITIAL ? `${middleName.trim().toUpperCase()}.` : middleName;
};

export const nameFormattingOptions = {
  firstNameDisplayedFirst: "firstNameDisplayedFirst",
  lastNameDisplayedFirst: "lastNameDisplayedFirst",
  fullNameThenFirstName: "fullNameThenFirstName",
};

const formatName = (props) => {
  if (!isObject(props)) return "";
  const {
    nameObject,
    format = "lastNameDisplayedFirst",
    defaultReturn = "",
  } = props;
  if (!isDefined(nameObject)) return defaultReturn;
  // set nameCase option lazy to false so that it uses the proper case when last name starts with a capital letter
  setOptions({ lazy: false });
  const {
    fullName = "",
    firstName = "",
    lastName = "",
    middleName = "",
    suffix = "",
    type = "",
    partyTypeCode = "",
  } = nameObject;

  if (compareStrings(type, "N") || compareStrings(partyTypeCode, "N")) {
    return fullName;
  }

  if (
    compareStrings(format, "fullNameThenFirstName") &&
    isNonEmptyString(fullName)
  ) {
    return nameCase(fullName, { lazy: false });
  }

  let first_name;
  let middle_name;
  let last_name;
  let suffix_var;
  let splitName = "";

  if (isNonEmptyString(firstName)) {
    splitName = [firstName, middleName, lastName, suffix]
      .map((word) => nameCase(word, { lazy: false }))
      .filter((item) => isNonEmptyString(item));
  }
  if (
    (stringIsEmptyOrNullish(firstName) || stringIsEmptyOrNullish(lastName)) &&
    isNonEmptyString(fullName)
  ) {
    splitName = fullName
      .split(" ")
      .map((word) => nameCase(word, { lazy: false }))
      .filter((item) => isNonEmptyString(item));
  }
  if (isString(nameObject)) {
    splitName = nameObject
      .split(" ")
      .map((word) => nameCase(word, { lazy: false }));
  }
  if (compareStrings(format, "lastNameDisplayedFirst")) {
    switch (splitName.length) {
      case 0:
        return defaultReturn;
      case 2:
        [first_name, last_name] = splitName;
        return `${last_name}, ${first_name}`;
      case 3:
        [first_name, middle_name, last_name] = splitName;
        return `${last_name}, ${first_name}\u00a0${formatMiddleName(
          middle_name
        )}`;
      case 4:
        [first_name, middle_name, last_name, suffix_var] = splitName;
        return `${last_name} ${suffix_var}, ${first_name}\u00a0${formatMiddleName(
          middle_name
        )}`;
      default:
        return splitName.join(" ");
    }
  }

  if (
    compareStrings(format, "firstNameDisplayedFirst") ||
    compareStrings(format, "fullNameThenFirstName")
  ) {
    switch (splitName.length) {
      case 0:
        return defaultReturn;
      case 2:
        [first_name, last_name] = splitName;
        return `${first_name} ${last_name}`;
      case 3:
        [first_name, middle_name, last_name] = splitName;
        return `${first_name} ${formatMiddleName(middle_name)} ${last_name}`;
      case 4:
        [first_name, middle_name, last_name, suffix_var] = splitName;
        return `${first_name} ${formatMiddleName(
          middle_name
        )} ${last_name} ${suffix_var}`;
      default:
        return splitName.join(" ");
    }
  }

  return defaultReturn;
};

const formatStatusCode = (status) => {
  if (!isString(status) || stringIsEmptyOrNullish(status)) return "-";
  const statuses = {
    IF: "In Force",
    NB: "New Business",
    NR: "Not Reported",
    TM: "Terminated",
    UN: "Unknown",
    UNK: "Unknown",
  };
  const formattedStatus = statuses[status];
  const STATUS_EXISTS = isDefined(formattedStatus);

  if (STATUS_EXISTS) {
    return formattedStatus;
  }
  return "-";
};

const formatBaseCoverageStatusCode = (status) => {
  if (!isString(status) || stringIsEmptyOrNullish(status)) return "-";
  const statuses = {
    IF: "Active",
    NB: "New Business",
    NR: "Not Reported",
    TM: "Terminated",
    UN: "Unknown",
    UNK: "Unknown",
  };
  const formattedBaseCoverageStatus = statuses[status];
  const STATUS_EXISTS = isDefined(formattedBaseCoverageStatus);

  if (STATUS_EXISTS) {
    return formattedBaseCoverageStatus;
  }
  return "-";
};

const formatRiderStatus = (status) => {
  if (!isString(status) || stringIsEmptyOrNullish(status)) return "-";
  const statuses = {
    ACT: "Active",
    TERM: "Terminated",
  };

  const formattedStatus = statuses[status];
  const STATUS_EXISTS = isDefined(formattedStatus);

  if (STATUS_EXISTS) {
    return formattedStatus;
  }
  return "-";
};

const formatAgentStatus = (status) => {
  if (!isString(status) || stringIsEmptyOrNullish(status)) return "Unknown";
  const statuses = {
    ACTP: "Active",
    TMP: "Terminated",
    UNK: "Unknown",
  };
  const formattedStatus = statuses[status];
  const STATUS_EXISTS = isDefined(formattedStatus);

  if (STATUS_EXISTS) {
    return formattedStatus;
  }
  return "Unknown";
};

const formatStatusReasonCode = (status, lineOfBusinessCode) => {
  if (!isString(status) || stringIsEmptyOrNullish(status)) return "-";

  const statuses = {
    common: {
      // use this if,
      // the description is not known to differ based on LOB
      AA: "Active Annuitized",
      AB: "Accelerated Benefit",
      AC: "Active",
      AI: "Application Received Incomplete",
      AL: "Issued As Alternate",
      AO: "Approved Tentative Offer",
      AP: "Approved",
      AQ: "Approved Pending Issue Requirements",
      AR: "Application Received",
      AS: "Active Single Premium",
      BA: "Backed-Approved",
      BC: "Backed Cond Issued",
      BI: "Backed-Issued",
      BK: "Booked",
      BM: "Back-Mailed",
      BN: "Beneficiary 5 Year Continuation",
      BP: "Backed-Pending",
      BS: "Backed-Submitted",
      CA: "Case Assignment Analyst",
      CC: "Cancelled IRS",
      CD: "Claim Paid",
      CH: "Change Pending",
      CI: "Conditionally Issued",
      CL: "Claim Pending",
      CM: "Completed",
      CN: "Cancelled",
      CP: "Change Pend After Issue",
      CR: "Policy Change Pending",
      CS: "Claim Resisted",
      CT: "Commuted",
      CV: "Converted",
      CY: "Claim Paying",
      DA: "Death Active",
      DB: "Disbursed",
      DC: "Death Claim",
      DD: "Denied",
      DE: "Death",
      DF: "Deferred Retirement",
      DG: "Death In Guarantee Period",
      DH: "Disability Hold",
      DL: "Deleted",
      DN: "Declined",
      DP: "Death Claim Pending",
      DR: "Dropped",
      DT: "Pend Death Claim Issue",
      EA: "Soft Credit Pull Error",
      EC: "Exchanged",
      EN: "Eligible Not Participating",
      EP: "Expired Paid Up",
      ER: "Early Retirement",
      ET: "Extended Term",
      EX: "Expired",
      FL: "Free Look",
      FV: "Free Look With CV",
      FZ: "Frozen",
      HA: "Hard Credit Pull Approved",
      HC: "Hard Credit Pull",
      IA: "Inactive",
      IC: "Incomplete After Issue",
      IM: "Income",
      IN: "Incomplete Pre-Issue",
      IS: "Issued",
      JD: "Joint Death",
      LC: "Life expectancy payout continuation",
      LG: "Pend Litigation Issue",
      LP: "Lapsed",
      LT: "Litigation",
      MA: "Matured",
      MG: "Migrated",
      MI: "Manually Issued",
      ML: "Mailed",
      MM: "Modified Matured",
      MP: "Maturity Pending",
      NA: "Not Available",
      NH: "Soft Credit Pull No Hit",
      NI: "Not Issued",
      NK: "Not Taken Pre-Issue",
      NO: "Normal Retirement",
      NR: "Not Taken Returned",
      NT: "Not Taken After Issue",
      OD: "One Deceased",
      OP: "Open",
      OV: "Overloan Protection",
      P2: "Pending Pt 2",
      PA: "Partial Accelerated Death Benefit",
      PC: "Conversion Pending",
      PD: "Pending Deletion",
      PE: "Pending",
      PF: "Pending First Payment",
      PK: "Pending Not Taken",
      PL: "Pending Lapse",
      PN: "Pending After Issue",
      PO: "Paid Off",
      PP: "Premium Paying",
      PS: "Pend Surrender Issue",
      PT: "Pending Litigation",
      PU: "Paid Up",
      PW: "Premium Waiver",
      PX: "Pending Expiry",
      PZ: "Processing Frozen",
      RA: "Re-Approved",
      RC: "Re-Conditionally Issued",
      RD: "Record Dropped",
      RE: "Rescind",
      RI: "Re-Issued",
      RM: "Re-Mailed",
      RN: "Replacement Internal",
      RO: "Reinstatement Offer",
      RP: "Re-Pended",
      RR: "Reported",
      RS: "Resubmitted",
      RT: "Return of premium death",
      RU: "Reduced Paid Up",
      S2: "Submitted Part 2 Only",
      SA: "Soft Credit Pull Approved",
      SB: "Submitted",
      SC: "Soft Credit Pull",
      SD: "Suicide-Incontestability",
      SE: "Surrendered Exchanged",
      SG: "Soft Credit Pull Cosigner",
      SI: "Surrendered Individual",
      SN: "Suspended",
      SO: "Split Option",
      SP: "Surrender Pending",
      SR: "Survey",
      SS: "Special Surrender",
      ST: "Issue Subject To Release",
      SU: "Surrendered",
      TA: "Till Approved",
      TM: "Temporary",
      TR: "Terminated",
      TT: "Temporary Term",
      UA: "Underwriter Approved",
      UC: "Undecided Contract Continuation",
      UN: "Unknown",
      UR: "Underwriter Responsible",
      US: "Senior Underwriter Approved",
      VP: "Alternate Payment Option",
      WO: "Claim Closed Without Payment",
      WT: "Withdrawn",
    },
    ANN: {
      // use this if the annuity description differs from other LOB
      CN: "Incomplete - Closed",
      DC: "Death Claim Paid",
      IS: "Issued Reportable",
      MG: "System Transfer",
      NA: "Not Applicable",
      NT: "Not Taken After Reported",
      PE: "Pending Requirements",
      PF: "Issued Not Reportable",
    },
  };
  const translationOverrideExists =
    hasKey({ obj: statuses, key: lineOfBusinessCode }) &&
    hasKey({
      obj: statuses[lineOfBusinessCode],
      key: status,
    });

  const formattedStatus = translationOverrideExists
    ? statuses[lineOfBusinessCode][status]
    : statuses.common[status];

  const STATUS_EXISTS = isDefined(formattedStatus);

  if (STATUS_EXISTS) {
    return formattedStatus;
  }
  return "-";
};
const isMassMutual = (str) => {
  if (!isString(str)) return false;
  return compareStrings("massmutual", str.toLowerCase());
};
const convertToTitleCase = (line) => {
  if (isNonEmptyString(line)) {
    return line
      .trim()
      .replace(/\s+/g, " ")
      .split(" ")
      .map((word) => {
        if (isMassMutual(word)) return "MassMutual";
        return word[0].toUpperCase() + word.substr(1).toLowerCase();
      })
      .join(" ");
  }
  return "";
};

const formatMoney = (amountOfMoney, defaultReturn = "") => {
  if (stringIsEmptyOrNullish(amountOfMoney)) {
    return defaultReturn;
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const IS_A_ZERO = [-0, +0, "-0", "0"].includes(amountOfMoney);
  const IS_A_NUMBER = typeof amountOfMoney === "number";
  const IS_A_STRING_NUMBER =
    typeof amountOfMoney === "string" &&
    !isNaN(amountOfMoney) &&
    amountOfMoney.trim() !== "";

  if (IS_A_ZERO) {
    return formatter.format(0);
  }
  if (IS_A_NUMBER) {
    return formatter.format(amountOfMoney);
  }
  if (IS_A_STRING_NUMBER) {
    return formatter.format(parseFloat(amountOfMoney));
  }
  return amountOfMoney;
};

const formatPercentage = (props, defaultReturn = "-") => {
  const { rate, decimalPlaces = 2, maxDecimalPlaces } = props;

  const NOT_A_NUMBER = isNaN(rate) || stringIsEmptyOrNullish(rate);
  if (NOT_A_NUMBER) return defaultReturn;
  const percentage = parseFloat(rate);
  const formattedPercentage = percentage.toLocaleString("en-US", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: maxDecimalPlaces,
  });

  return `${formattedPercentage}%`;
};

// formats US phone number
const formatPhoneNumberObject = (phoneNumberObject, defaultReturn = "") => {
  const INVALID_PHONE_NUMBER_OBJECT =
    isObject(phoneNumberObject) &&
    !isString(phoneNumberObject?.areaCode) &&
    !isString(phoneNumberObject?.number);
  if (INVALID_PHONE_NUMBER_OBJECT) {
    return { phoneNumber: defaultReturn, formattedPhoneNumber: defaultReturn };
  }
  const phoneNumber = `${phoneNumberObject?.areaCode}${phoneNumberObject.number}`;
  const formattedPhoneNumber = formatPhoneNumberString(phoneNumber);
  const VALID_PHONE_NUMBER = phoneNumber.length > 0;
  return VALID_PHONE_NUMBER
    ? { phoneNumber, formattedPhoneNumber }
    : { phoneNumber: defaultReturn, formattedPhoneNumber: defaultReturn };
};
const formatPhoneNumberString = (phoneNumberString) => {
  if (!isString(phoneNumberString)) {
    return phoneNumberString;
  }
  const removeNonIntegerCharacters = phoneNumberString.replace(/\D/g, "");
  // if there's a country and area code
  if (removeNonIntegerCharacters.length === 11) {
    const countryCode = removeNonIntegerCharacters.substring(0, 1);
    const areaCode = removeNonIntegerCharacters.substring(1, 4);
    const phoneNumberPart1 = removeNonIntegerCharacters.substring(4, 7);
    const phoneNumberPart2 = removeNonIntegerCharacters.substring(7, 11);

    return `${countryCode}-${areaCode}-${phoneNumberPart1}-${phoneNumberPart2}`;
  }
  // just area code
  if (removeNonIntegerCharacters.length === 10) {
    const areaCode = removeNonIntegerCharacters.substring(0, 3);
    const phoneNumberPart1 = removeNonIntegerCharacters.substring(3, 6);
    const phoneNumberPart2 = removeNonIntegerCharacters.substring(6, 10);

    return `${areaCode}-${phoneNumberPart1}-${phoneNumberPart2}`;
  }
  return phoneNumberString;
};

const convertStateAbbreviationToWord = (
  stateAbbreviation,
  defaultReturn = ""
) => {
  if (!isString(stateAbbreviation) || stringIsEmptyOrNullish(stateAbbreviation))
    return defaultReturn;
  const stateFullName = states[stateAbbreviation];
  if (stateFullName) {
    return stateFullName;
  }
  return stateAbbreviation;
};

// removes preceding zeroes from id ie 00000093432 into 93432
const cutPrecedingZerosFromId = (id) => +id || "";

const amountToDisplay = (amount) =>
  isDefined(amount) ? formatMoney(amount) : "$0.00";

const extractNumberFromString = (value, defaultReturn = null) => {
  if (isNumber(value)) {
    return value;
  }
  if (isString(value)) {
    // removes everything except .'s and -'s
    const removeAllNonNumber = value?.replace(/[^\d.-]/g, "");
    if (removeAllNonNumber) {
      return parseFloat(removeAllNonNumber);
    }
    return defaultReturn;
  }

  return defaultReturn;
};

const formatAddress = (
  address = {},
  addPostalCodeExtension = true,
  defaultReturn = "-"
) => {
  if (isEmptyObject(address) || compareStrings(address, "")) {
    return { addrFirstLine: defaultReturn };
  }
  const {
    attentionLine,
    line1,
    addressLine1,
    line2,
    addressLine2,
    line3,
    addressLine3,
    city,
    state,
    postalCode,
    zip,
    zipCode,
    postalCodeExtension1,
    countryName,
    countryCode,
  } = address;

  const attnLine = attentionLine || "";
  const correctLine1 = line1 || addressLine1 || "";
  const correctLine2 = line2 || addressLine2 || "";
  const correctLine3 = line3 || addressLine3 || "";
  const correctZip = postalCode || zip || zipCode || "";
  const correctZipExtension =
    isDefined(postalCodeExtension1) && addPostalCodeExtension
      ? `-${postalCodeExtension1}`
      : "";
  const country = countryName || "";
  const threeLetterCountryCode = countryCode || "";

  const addressLine1Formatted = convertToTitleCase(correctLine1);
  const addressLine2Formatted = convertToTitleCase(correctLine2);

  const cityFormatted = convertToTitleCase(city);
  const countryNameFormatted = getCountryCode(country, threeLetterCountryCode);

  const stateFormatted = state || "";

  const addrFirstLine = addressLine1Formatted;
  const addrSecondLine = addressLine2Formatted;

  const addressLine3Formatted = convertToTitleCase(correctLine3);
  const addrThirdLine = addressLine3Formatted;

  const addrFourthLine =
    `${cityFormatted} ${stateFormatted} ${correctZip}${correctZipExtension} ${countryNameFormatted}`.trim();

  if (
    !isDefined(addrFirstLine) &&
    !isDefined(addrSecondLine) &&
    !isDefined(addrThirdLine)
  ) {
    return { addrFirstLine: defaultReturn };
  }

  return {
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
  };
};

const formatAgencyName = (agent) => {
  const { agencyDoingBusinessAs, homeAgencyId } = agent;
  const formattedAgencyDoingBusinessAs = convertToTitleCase(
    agencyDoingBusinessAs
  );
  const lengthOfAgencyId = 3;
  const formattedHomeAgencyId = getLastXChars(
    homeAgencyId,
    lengthOfAgencyId
  ).padStart(lengthOfAgencyId, 0);

  const AGENCY_NAME_AND_ID_AVAILABLE = agencyDoingBusinessAs && homeAgencyId;
  const ONLY_ID_AVAILABLE = !agencyDoingBusinessAs && homeAgencyId;
  const ONLY_AGENCY_NAME_AVAILABLE = agencyDoingBusinessAs && !homeAgencyId;

  if (AGENCY_NAME_AND_ID_AVAILABLE) {
    return `${formattedAgencyDoingBusinessAs} (${formattedHomeAgencyId})`;
  }
  if (ONLY_ID_AVAILABLE) {
    return `${formattedHomeAgencyId}`;
  }
  if (ONLY_AGENCY_NAME_AVAILABLE) {
    return `${formattedAgencyDoingBusinessAs}`;
  }
  return "-";
};

const formatBeneficiaryType = (benficiaryType) => {
  if (!isDefined(benficiaryType)) return "-";
  switch (benficiaryType.toLocaleLowerCase()) {
    case "individual":
      return "Individual";
    case "revocable-trust":
      return "Revocable Trust";
    case "trust-under-ins-will":
      return "Trust under insured's will";
    case "estate-of-insured":
      return "Insured's Estate";
    case "corporation":
      return "Corporation";
    case "irrevocable-trust":
      return "Irrevocable Trust";
    case "other-entity":
      return "Other entity";
    case "lawful-children-of-insured":
      return "Lawful Children of Insured";
    case "marriage-children-of-insured":
      return "Children born of the marriage and/or legally adopted by the Insured";
    default:
      return benficiaryType;
  }
};

const formatBeneName = (props) => {
  const { beneType, nameObject, isHapPolicy } = props;
  const nonIndividualTypes = [
    "revocable-trust",
    "corporation",
    "other-entity",
    "estate-of-insured",
    "irrevocable-trust",
    "lawful-children-of-insured",
    "trust-under-ins-will",
    "marriage-children-of-insured",
  ];

  const nonHapAndNonIndividual =
    !isHapPolicy && compareArrayOfStrings(nonIndividualTypes, beneType);
  const hapAndNonIndividual =
    isHapPolicy && !compareArrayOfStrings("individual", beneType.toString());

  if (nonHapAndNonIndividual || hapAndNonIndividual) {
    const noFullNameOrEmpty =
      stringIsEmptyOrNullish(nameObject.fullName) || !isDefined(nameObject);

    return noFullNameOrEmpty ? "-" : convertToTitleCase(nameObject.fullName);
  }

  return formatName({
    nameObject,
    format: "firstNameDisplayedFirst",
    defaultReturn: "-",
  });
};

const getLastXChars = (str, length) => {
  if (!isNonEmptyString(str) || !isNumber(length)) return "";
  return str.substr(-length);
};

const concatFirstLastName = (firstName, lastName, fullName) => {
  if (firstName && lastName) {
    return capitalizeFirstLetter(nameCase(`${firstName} ${lastName}`));
  }
  return capitalizeFirstLetter(nameCase(fullName));
};
const formatDocumentType = (doc, lineOfBusinessCode, agreementKeyAdmin) => {
  const { documentType, billType, displayName } = doc;
  if (!isString(documentType) || stringIsEmptyOrNullish(documentType))
    return "";
  if (compareStrings(documentType, "1099")) return documentType;
  if (compareStrings(documentType, "PACRETURN")) return displayName;
  if (compareStrings(documentType, "BILL") && stringIsEmptyOrNullish(billType))
    return "Notice of Payment Due";
  if (compareStrings(documentType, "BILL")) return billType;
  if (
    isDefined(displayName) &&
    compareArrayOfStrings(["CONFIRM", "CNTRPACK", "ANNLSTMT"], documentType)
  )
    return displayName;

  if (compareStrings(documentType, "PREM CHG")) return "Level Premium Change";
  if (
    compareStrings(documentType, "POLPACK") &&
    compareStrings(agreementKeyAdmin, "HAP")
  )
    return "EPOLICY 1035X";

  if (
    compareArrayOfStrings(["LIFE", "DI", "ANN"], lineOfBusinessCode) &&
    !stringIsEmptyOrNullish(displayName)
  ) {
    return displayName;
  }
  const docTypes = {
    ANNLSTMT: "Annual Statement",
    CBR: "Checkbook Reminder",
    CONFIRM: "Confirmation Document",
    CNTRPACK: "eContract Package",
    NBPOLPAGES: "Duplicate Copy of Policy",
    PAYNOTICE: "Record of Payment",
    LNCONFIRM: "Loan Statement",
    OWNBENASGN: "Beneficiary Change Confirmation",
    MISCORRCASHVAL: "Cash Value Quote",
    MISCORRPOLVER: "Policy Coverage Summary",
    POLPACK: "Duplicate Copy of Policy",
    SGNPOLPACK: "Duplicate Copy of Policy",
    LISRLTR: "Recommended LISR Premium Increase",
    ADDCHG: "Address Change Confirmation",
    GIRLTR: "GIR Option Notification",
    PAYEECONF: "Change Confirmation",
    ALIRCONF: "ALIR Premium Change Confirmation",
    STATEMENT: "Annual Statement",
    BENECHNGNOT: "Beneficiary Change Confirmation",
    BENECHGNOT: "Beneficiary Change Confirmation",
    PTLMAIL: "Premium Level Term Letter",
  };

  return docTypes[documentType];
};

export const handleDateUndefinedSorting = (dateFieldA, dateFieldB) => {
  if (
    stringIsEmptyOrNullish(dateFieldA) &&
    stringIsEmptyOrNullish(dateFieldB)
  ) {
    return 0;
  }
  if (stringIsEmptyOrNullish(dateFieldA) || dateFieldB === "-") {
    return -1;
  }
  return 1;
};

export const formatDocumentData = (
  agreementData,
  data,
  lineOfBusinessCode = ""
) => {
  const formattedDocuments = data.map((document, index) => {
    const { effectiveDate, billType, mimeType, objectStore, guid } = document;
    return {
      id: index,
      formattedEffectiveDate: formatEffectiveDate(agreementData, document),
      documentType: formatDocumentType(
        document,
        lineOfBusinessCode,
        agreementData?.agreementKeyAdmin
      ),
      effectiveDate,
      documentURL: BlankPDF,
      billType,
      mimeType,
      objectStore,
      guid,
    };
  });
  return formattedDocuments.sort((a, b) => {
    const dateA = createDateObject(a.formattedEffectiveDate);
    const dateB = createDateObject(b.formattedEffectiveDate);

    const compareResult = compareDesc(dateA, dateB);

    if (isNaN(compareResult)) {
      return handleDateUndefinedSorting(
        a.formattedEffectiveDate,
        b.formattedEffectiveDate
      );
    }

    return compareResult;
  });
};

export const formatEffectiveDate = (agreementData, document) => {
  const { documentType, scanDate, effectiveDate } = document;

  if (
    compareStrings(documentType, "BILL") ||
    compareStrings(documentType, "LISRLTR") ||
    isFoundationFlexiblePremium(agreementData) ||
    isFoundationSinglePremium(agreementData) ||
    isFlexIVFund1FPOrFund4FP(agreementData?.kindCode)
  ) {
    return formatDate(scanDate, "", "-");
  }
  return formatDate(effectiveDate, "", formatDate(scanDate, ""));
};

export const formatProductType = (product) => {
  if (stringIsEmptyOrNullish(product)) return product;
  const productTypes = {
    LIFE: "Life Insurance",
    ANN: "Annuity",
    LCM: "Large Corporate Market",
    INVS: "Investment Products",
    LENDING: "Lending",
    IMG: "Income Management Group",
    ACCIDENT: "Accident Insurance",
    DI: "Disability",
    RETIRE: "Retirement Products",
    "CRIT ILL": "Critical Illness Insurance",
    LTC: "Long Term Care",
  };
  return productTypes[product];
};

export const formatLineOfBusiness = (props) => {
  const { lineOfBusinessCode, lineOfBusinessName } = props;
  if (stringIsEmptyOrNullish(lineOfBusinessCode)) return lineOfBusinessCode;
  if (compareStrings(lineOfBusinessCode, "DI")) return lineOfBusinessCode;

  const isAnnuity = compareStrings(lineOfBusinessCode, "ANN");
  const annutyLineoOfBusinessName = isNonEmptyString(lineOfBusinessName)
    ? convertToTitleCase(lineOfBusinessName)
    : "Annuity";
  return isAnnuity
    ? annutyLineoOfBusinessName
    : convertToTitleCase(lineOfBusinessCode);
};
export const formatTransactionDescription = (transactionCode) => {
  if (!isDefined(transactionCode)) return null;
  switch (removeAllWhiteSpace(transactionCode.toLocaleUpperCase())) {
    case "WB":
      return "Policy Anniversary";
    case "TA":
      return "Full Surrender";
    case "XP":
      return "Partial Cost of Insurance";
    case "XL":
      return "Deduction Calculation";
    case "XA":
      return "Cost of Insurance";
    case "RA":
      return "Reinstatement Payment";
    case "TE":
      return "Collateral Interest Credit";
    case "TD":
      return "Loan Interest Capitalization";
    case "TC":
      return "Cash Loan Transaction";
    case "ME":
      return "Excess Loan Payment";
    case "MA":
      return "Dump-in Payment";
    case "NA":
      return "Regular Payment";
    case "NE":
      return "Loan Repayment";
    case "ND":
      return "Disability Payment";
    default:
      return null;
  }
};

export const sortPolicyOwners = (policyOwners) => {
  const reducedPolicyOwners = removeOwnerAndInsuredDuplicates(policyOwners);
  return reducedPolicyOwners.sort((a, b) => {
    if (
      compareStrings(a.roleSubType, "LIST") &&
      !compareStrings(b.roleSubType, "LIST")
    ) {
      return -1;
    }
    if (
      compareStrings(b.roleSubType, "LIST") &&
      !compareStrings(a.roleSubType, "LIST")
    ) {
      return 1;
    }
    return 0;
  });
};

export const sortInsuredPeople = (insuredPeople) => {
  const reducedInsuredPeople = removeOwnerAndInsuredDuplicates(insuredPeople);
  return reducedInsuredPeople.sort((a, b) => {
    if (
      compareStrings(a.roleSubType, "PRMR") &&
      !compareStrings(b.roleSubType, "PRMR")
    ) {
      return -1;
    }
    if (
      compareStrings(b.roleSubType, "PRMR") &&
      !compareStrings(a.roleSubType, "PRMR")
    ) {
      return 1;
    }
    return 0;
  });
};

export const formatContactUsNumber = (contactUsNumber, defaultReturn = "") => {
  const result =
    !isDefined(contactUsNumber) && defaultReturn
      ? defaultReturn
      : contactUsNumber;
  return formatPhoneNumberString(result);
};

export const formatContactUsDateTime = (
  contactUsDateTime,
  defaultReturn = ""
) =>
  !isDefined(contactUsDateTime) && defaultReturn
    ? defaultReturn
    : contactUsDateTime;

export const formatAnnuityProductName = (props) => {
  const {
    productName,
    funds,
    surrChargeSchedule = "",
    productPrefix,
    agreementData = {},
  } = props;
  const fund = funds?.fund;
  const { kindCode, productTypeName } = agreementData;
  if (isFlexExtraAnnuityProduct(kindCode)) return productTypeName;

  if (!productName) return "";

  if (isStableVoyageAnnuityProduct(productPrefix)) {
    if (!isDefined(fund)) return productName;
    const guaranteePeriods = fund.filter((fundEntry) => fundEntry.balance > 0);
    if (guaranteePeriods.length === 1) {
      return `${productName}, ${guaranteePeriods[0].displayName}`;
    }
  }
  if (isOdysseyAnnuityProduct(productPrefix)) {
    if (compareStrings(surrChargeSchedule, "")) return productName;
    return `${productName}, ${surrChargeSchedule}`;
  }
  return productName;
};

export const formatSupTagIfPresent = (string) => {
  if (!string) return "";
  const openingTag = string.search("<sup>");
  const closingTag = string.search("</sup>");
  if (openingTag === -1 || closingTag === -1) return string;
  const firstPortion = string.substring(0, openingTag);
  const secondPortion = string.substring(openingTag + 5, closingTag);
  const finalPortion = string.substring(closingTag + 6);

  if (compareStrings(secondPortion, "&reg;")) {
    return (
      <span>
        {firstPortion}
        <sup>&reg;</sup>
      </span>
    );
  }
  return (
    <span>
      {firstPortion}
      <sup>{secondPortion}</sup>
      {finalPortion}
    </span>
  );
};

export const extractSupTagIfPresent = (string) => {
  if (!string) return "";
  const openingTag = string.search("<sup>");
  const closingTag = string.search("</sup>");
  if (openingTag === -1 || closingTag === -1) return string;
  const firstPortion = string.substring(0, openingTag);
  const secondPortion = string.substring(openingTag + 5, closingTag);
  const finalPortion = string.substring(closingTag + 6);

  let extractedText = "";
  if (compareStrings(secondPortion, "&reg;")) {
    extractedText = firstPortion;
  } else {
    extractedText = `${firstPortion} ${secondPortion} ${finalPortion}`;
  }
  return extractedText;
};

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const maskAccountNumber = (accountNumber) => {
  if (isDefined(accountNumber)) {
    return accountNumber.replace(/.(?=.{4})/g, "x");
  }
  return "";
};

export const formatLineOfBusinessCode = (lineOfBusinessCode) => {
  const productTypes = {
    LIFE: "Life",
    ANN: "Annuity",
    DI: "Disability",
  };
  return productTypes[lineOfBusinessCode] || "-";
};

export {
  amountToDisplay,
  capitalize,
  concatFirstLastName,
  convertStateAbbreviationToWord,
  isMassMutual,
  convertToTitleCase,
  cutPrecedingZerosFromId,
  extractNumberFromString,
  formatAddress,
  formatMoney,
  formatName,
  formatPercentage,
  formatPhoneNumberString,
  formatPhoneNumberObject,
  formatAgentStatus,
  formatRiderStatus,
  formatStatusCode,
  formatBaseCoverageStatusCode,
  formatStatusReasonCode,
  formatDocumentType,
  formatAgencyName,
  getLastXChars,
  formatBeneficiaryType,
  formatBeneName,
};
